import { Card, Col, Row } from "antd";
import { Outlet, useLoaderData, Form, useSubmit, redirect } from "react-router-dom";
import { fetchTeacherClassesSubjects } from "../../../http/teacherApi";
import Select from 'react-select'


export async function silabusLoader({ params }: any) {
    const moduleID = params.moduleID;
    const { selectedClassSubject } = params;
    const rawClassesSubjects = await fetchTeacherClassesSubjects();
    const classesSubjects = rawClassesSubjects.map((classub: any) => { return ({ value: classub.id, label: classub.className + ' / ' + classub.subjectName }) })
    //console.log("silabusLoader", params);

    return { moduleID, classesSubjects, selectedClassSubject };
}

export async function silabusAction({ request, params }: any) {
    const formData = await request.formData();
    //    console.log("silabusAction", formData);

    const updates = Object.fromEntries(formData);
    return redirect(`${updates.classsubject}/modules`);
}

export default function Silabus() {

    const submit = useSubmit();
    const { moduleID, classesSubjects, selectedClassSubject } = useLoaderData() as any;

    //const { teacher } = useContext(Context)

    const onSelect = (e: any) => {
        //console.log(e);

        let formData = new FormData();
        formData.append("classsubject", e?.value);
        submit(formData, { method: "post" });
        //teacher.setSelectedClasSubjectSyllabus(e.target.value)
    }

    return (<>
        <Card>
            <Row gutter={[8, 8]}>
                <Col span={8}>
                    <Form method="POST">
                        <Select
                            key={'csdfsdf'}
                            onChange={onSelect}
                            name="classsubject" /* style={{ width: '100%' }}  */
                            placeholder='Выберите класс/предмет'
                            value={classesSubjects.filter((item: any) => item.value === selectedClassSubject)}
                            options={classesSubjects}
                        />
                        {/*                             {classesSubjects.map((classub: any) =>
                                <option key={'cs' + classub.id} value={classub.id}>{classub.className + ' / ' + classub.subjectName}</option>
                            )}
                        */}
                    </Form>
                </Col>
            </Row>
        </Card>

        <Card>
            <Outlet />
            {/*        {!moduleID &&
                <>
                    <SchoolBook classID={getClassID()} subjectID={getSubjectID()} />
                    <ModulesList classID={getClassID()} subjectID={getSubjectID()} />
                </>
            } */}
        </Card >
    </>
    )
}

