import { message } from "antd";
import { $authHost } from "./index";

export const getStudentsByParent = async () => {
    try {
        const { data } = await $authHost.get('api/parent/students/')
        return data
    } catch {
        return null;
    }
}

export const fetchStar1DataBSID = async (studentID, tacts = '') => {
    const { data } = await $authHost.get('api/parent/star1?studentID=' + studentID + '&tacts=' + tacts)
    return data
}

export const fetchStar2DataBSID = async (subjectID, studentID) => {
    const { data } = await $authHost.get('api/parent/star2?subjectID=' + subjectID + '&studentID=' + studentID)
    return data
}

export const fetchStar3DataBSID = async (moduleID, studentID) => {
    const { data } = await $authHost.get('api/parent/star3?moduleID=' + moduleID + '&studentID=' + studentID)
    return data
}

export const fetchStarSDataBSID = async (studentID) => {
    const { data } = await $authHost.get('api/parent/starS?studentID=' + studentID)
    return data
}

export const fetchVisitIntervals = async (studentID) => {
    const { data } = await $authHost.get('api/parent/visitintervals?studentID=' + studentID)
    return data
}

export const fetchStudentCarers = async (studentID) => {
    const { data } = await $authHost.get('api/parent/studentcarers?studentID=' + studentID)
    return data
}

export const createVisitInterval = async (intervalID, studentID, parentID, begin, end) => {
    const { data } = await $authHost.post('api/parent/visitinterval', { intervalID, studentID, parentID, begin, end })
    return data
}

export const deleteVisitInterval = async (intervalID) => {
    const { data } = await $authHost.delete('api/parent/visitinterval/' + intervalID)
    return data
}

export const fetchMKIntervals = async (studentID) => {
    try {
        const { data } = await $authHost.get('api/parent/mkintervals?studentID=' + studentID)
        return data
    } catch {
        return null;
    }
}

export const createMKInterval = async (intervalID, studentID, carers, begin, end) => {
    const { data } = await $authHost.post('api/parent/mkinterval', { intervalID, studentID, carers, begin, end })
    return data
}

export const deleteMKInterval = async (intervalID) => {
    const { data } = await $authHost.delete('api/parent/mkinterval/' + intervalID)
    return data
}

export const fetchSubjectsTacts = async (studentID) => {
    try {
        const { data } = await $authHost.get('api/parent/subjecttacts/' + studentID)
        return data
    } catch (e) {
        //message.error("Ошибка получения тактов!", e?.name, e?.message);
        return null
    }
} 

export const fetchSkillTacts = async (studentID) => {
    try {
        const { data } = await $authHost.get('api/parent/skilltacts/' + studentID)
        return data
    } catch (e) {
        //message.error("Ошибка получения тактов!", e?.name, e?.message);
        return null
    }
} 
