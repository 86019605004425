import { Outlet, useLoaderData } from "react-router-dom";


export async function loader({ params }: any) {
    const moduleID = params.moduleID;
    return { moduleID };
}

export default function Module() {
    const { moduleID } = useLoaderData() as any;
    return (
        <>
        {moduleID}
        <Outlet />
        </>
    )
}

