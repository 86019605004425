import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
//import App, { router } from './App';
import AdminStore from './store/AdminStore.js';
import /* UserStore,  */ { User } from "./store/User.js";
import StudentStore from './store/StudentStore.js';
import TeacherStore from './store/TeacherStore.js';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import ParentStore from './store/ParentStore.js';
import TutorStore from './store/TutorStore.js';
//import { router } from './components/AppRouter.js_/index';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Store from './store/store';
import ErrorPage from './components/ErrorPage';
import Admin, { loader } from './pages/Admin.js';
import Student from './pages/Student.js';
import Teacher, { teacherRouter } from './pages/Teacher.js';
import Tutor, { tutorRouter } from './pages/Tutor.js';
import Parent from './pages/Parent.js';
import Auth, { action } from './pages/Auth';
import RequireAuth from './hoc/RequireAuth.jsx';
import Test, { testLoader } from './pages/Test';
import NotFound from './pages/NotFound';
import App, { rootLoader } from './App';
//import App from './backup/test_table.js';
import Redirect from './pages/Redirect';
import { router as adminRouter } from './pages/Admin.js'


interface State {
   /* user: User; */
   store: Store;
   admin: AdminStore;
   student: StudentStore;
   teacher: TeacherStore;
   parent: ParentStore;
   tutor: TutorStore;
}

export const store = new Store();
export const admin = new AdminStore();
const student = new StudentStore();
const teacher = new TeacherStore();
const parent = new ParentStore();
const tutor = new TutorStore();
const user = new User();

export const Context = createContext<State>({
   /*  user, */
   store,
   admin,
   student,
   teacher,
   parent,
   tutor
});

export const fakeLoader = async () => {
   console.log("fake loader");
   return null;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const router = createBrowserRouter([
   {
      path: "/*", element: <App />, errorElement: <ErrorPage />, loader: rootLoader,
      children: [
         { index: true, element: <Redirect />, loader: fakeLoader },
         //         { path:'admin', element:<Navigate to="/ADMIN" replace />},
         { path: 'ADMIN/*', element: < RequireAuth >< Admin /></RequireAuth>, loader: loader, children: adminRouter, },
         { path: 'student/*', element: < RequireAuth >< Student /></RequireAuth>, loader: fakeLoader },
         { path: 'teacher/*', element: < RequireAuth >< Teacher /></RequireAuth>, children: teacherRouter },
         { path: 'tutor/*', element: < RequireAuth >< Tutor /></RequireAuth>, loader: fakeLoader, children: tutorRouter },
         { path: 'parent/*', element: < RequireAuth >< Parent /></RequireAuth>, loader: fakeLoader },
         { path: 'login', element: < Auth />, action: action, loader: fakeLoader },
         { path: 'test', element: < RequireAuth > <Test /></RequireAuth >, loader: testLoader },
         //{ path: '*', element: <ErrorPage />, loader: fakeLoader }, {/* < NotFound />  */ }
      ]
   },
]);

root.render(
   <React.StrictMode>
      <ConfigProvider locale={ruRU}>
         <Context.Provider value={{ /*  user, */ store, admin, student, teacher, parent, tutor }}>
            <RouterProvider router={router} />
         </Context.Provider>
      </ConfigProvider>
   </React.StrictMode>

);

