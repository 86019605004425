import React, { FC, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Button, Container, FormControl } from 'react-bootstrap';
import { getFileList } from '../../../http/teacherApi';
import FilesUpload from '../../utilites/FilesUpload';

interface SchoolBookType {
    classID: any;
    subjectID: any;
  
}

const SchoolBook = observer((props:SchoolBookType) => {
    const {classID, subjectID} = props
    const [afileList, setFileList] = useState(null)
    const [aId, setAId] = useState('')

    useEffect(() => {
        setAId(String(Number(classID) * 1000 + Number(subjectID)))
      //  console.log("SH",data,aId,data.classID * 1000 + data.subjectID);
        loadData(String(Number(classID) * 1000 + Number(subjectID)))
    }, [classID, subjectID])

    const loadData = async (aId:string) => {
/*         const data = await getFileList('schoolbook', aId)
        setFileList(data) */
        await getFileList('schoolbook', aId).then(data => setFileList(data))
       // console.log("SH1",data);
    }

    return (
        <Container style={{'marginTop': '15px', 'marginBottom': '15px'}}>
            Материалы:&nbsp; 
            <FilesUpload
                  fileList={afileList}
                  action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'schoolbook'}&id=${aId}`}
                >
            </FilesUpload>
        </Container>
    );
});

export default SchoolBook;