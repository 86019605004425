import { NavLink, Navigate, useRouteError } from 'react-router-dom';
import { FC, useContext } from 'react';
import img from '../img/image.jpeg';
import { Card } from 'antd';
import { Context } from '..';

const ErrorPage: FC = () => {
    const { store } = useContext(Context);

    const error: unknown = useRouteError();
    console.error("ERROR PAGE: ", error);

    if (!store.isAuth) {
        return (
            <Navigate to={"/"}/>
        )
    }
    

    return (
        <Card style={{textAlign: 'center'}}>
            <div id="error-page">
                <img src={img} alt=':(' />
                <h1>Упс!</h1>
                <p>Извините, произошла непредвиденная ошибка.</p>
                <p>
                    <i>{(error as Error)?.message ||
                        (error as { statusText?: string })?.statusText}</i>
                </p>
                <NavLink to="/">Главная</NavLink>
            </div>
        </Card>
    );
}

export default ErrorPage;