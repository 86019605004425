import { observer } from "mobx-react-lite";
import '../navbar.css'
import { NavLink } from 'react-router-dom';

const MenuBar = (params) => {
  const { menu } = params;
  
  return (
    <div id='sidebar'>
      <nav>
        <ul>
          {menu.map((item) => (
            <li key={item.id}>
              <NavLink to={`${item.link}`}
                className={({ isActive, isPending }) =>
                  isActive
                    ? "active"
                    : isPending
                      ? "pending"
                      : ""
                }
              >
                {item.name || item.last ? (
                  <>
                    {item.name} {item.last}
                  </>
                ) : (
                  <i>No Name</i>
                )}{" "}
                {item.favorite && <span>★</span>}
              </NavLink>
            </li>
          ))}
        </ul> 
      </nav>

    </div>
  );
};

export default MenuBar;