import { Button, Card, Col, DatePicker, Row } from "antd";
import { Typography } from 'antd';
import { useEffect, useState } from "react";
import { BiAddToQueue, BiCommentAdd, BiDotsHorizontal, BiPlus, BiSolidAddToQueue, BiTrash } from "react-icons/bi";
import { createTheme, deleteModule, deleteTheme, fetchModules, modyfyModuleO, modyfyThemeO, oneModule, oneTheme } from "../../http/teacherApi";
import dayjs from 'dayjs'
import MyDatePicker from "../MyDatePicker";
import Select from 'react-select'
import { fetchWorkTypes } from "../../http/adminApi";
import { Form, redirect, useLoaderData } from "react-router-dom";

const { Paragraph } = Typography;
dayjs.locale('ru')

export async function loaderThemeCard({ params }: any) {
    console.log("theme card loader", params);

    const rawWorkTypes = await fetchWorkTypes();
    const workTypes = rawWorkTypes.map((item: any) => { return { value: item.id, label: item.name, color: '#00B8D9' } });


    const { themeID } = params;
    if (themeID) {
        const theme = await oneTheme(themeID);
        return { theme, workTypes }
    }

    return null
}

export async function createThemeCard({ params }: any) {
    const { moduleID } = params;
        const ret = await createTheme(moduleID);
        console.log("new themeID:", ret);
        return redirect('../' + ret);
}

export async function destroyThemeCard({ params, request }: any) {
    console.log("destroyThemeCard", params, request);
    const { themeID, moduleID } = params
    if (themeID) {
        await deleteTheme(themeID);

    }
    return redirect('../../'+moduleID);
}


export async function actionThemeCard({ params, request }: any) {
    console.log("actionThemeCard", params, request);
    const { themeID } = params
    if (themeID) {
        //await deleteTheme(themeID);
    }
    return redirect('../..');
}



interface Module {
    id: number,
    index?: string,
    name?: string,
    begin?: string | null,
    end?: string,
    workType?: number
}

const ThemeCard = (params: { id: number | any }) => {

    const { workTypes, theme } = useLoaderData() as any;

    const [workTypes1, setWorkTypes] = useState([])
    const [module, setModule] = useState<Module>({ id: 0 });

    /*  useEffect(() => {
         load(id);
     }, []);
 
     const load = async (id: any) => {
         const ret = await oneTheme(id);
         setModule(ret);
         const rawWorkTypes = await fetchWorkTypes();
         setWorkTypes(rawWorkTypes.map((item: any) => { return { value: item.id, label: item.name, color: '#00B8D9' } }));
     }
  */

    useEffect(() => {
        //loadModule(id);
        setModule(theme);
        setWorkTypes(workTypes);
    }, [theme, workTypes]);

    const change = async (value: any) => {
        console.log("ch idx", value);

        setModule({ ...module, ...value });
        modyfyThemeO({ themeID: module.id, ...value })

    }

    return (
        <Card title={'Тема: ' + module.name} key={'theme' + module.id}>
            <Row gutter={[4, 4]}>
                <Col span={6}>
                    Порядковый номер
                </Col>
                <Col span={18}>
                    <Paragraph
                        editable={{
                            tooltip: 'Порядковый номер',
                            onChange: (value) => change({ index: value }),
                            triggerType: ['icon', 'text'],
                        }}
                    >
                        {String(module.index)}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    Разделы
                </Col>
                <Col span={18}>
                    <Paragraph
                        editable={{
                            tooltip: 'Введите название',
                            onChange: (value) => change({ name: value }),
                            triggerType: ['icon', 'text'],
                        }}
                    >
                        {module.name}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    Тип контроля
                </Col>
                <Col span={18}>
                    <Select
                        onChange={(value: any) => change({ workType: value?.value })}
                        name="workType" /* style={{ width: '100%' }}  */
                        placeholder='Выберите тип контроля'
                        value={workTypes.filter((item: any) => item.value === module.workType)}
                        options={workTypes}
                    />
                </Col>

                <Col span={6}>
                    Начало
                </Col>
                 <Col span={18}>
                    <DatePicker name="begin" defaultValue={dayjs(module?.begin).isValid() ? dayjs(module?.begin) : undefined}  onChange={(value) => change({ begin: dayjs(value).format() })} /> 
                </Col>
                <Col span={6}>
                    Завершение
                </Col>
                <Col span={18}>
                    <DatePicker name="end" defaultValue={dayjs(module?.end).isValid() ? dayjs(module?.end) : undefined} onChange={(value) => change({ end: dayjs(value).format() })} />
                </Col>

                <Col span={6}>
                </Col>
                <Col span={18}>
                <Form
                        action={/* String(module1?.id) + */ 'destroy'}
                        method="post"
                        onSubmit={(event) => {
                            console.log(event);

                            if (
                                !window.confirm(`Подтвердите удаление`)
                            ) {
                                event.preventDefault();
                            }

                        }}

                    >
                        <button className='float-end btn btn-outline-danger'>
                            <BiTrash className="justify-content-between" />
                            Удалить тему
                        </button>
                    </Form>

                </Col>

            </Row>
        </Card>
    )
}


export default ThemeCard;