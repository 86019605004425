import React, { useContext, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Container, Form } from 'react-bootstrap';
import ModulesList from './ModulesList';
import SchoolBook from './SchoolBook';
import { Card } from 'antd';
import { Outlet, useLoaderData } from 'react-router-dom';

export async function modulesLoader({ params }) {
    //nst contact = await getContact(params.contactId);
    const moduleID = params.moduleID;
    console.log("loader:", moduleID);
    return { moduleID };
}


const TeacherModulesTableNM = observer(() => {
    const { moduleID } = useLoaderData();
console.log("moduleID",moduleID);

const { teacher } = useContext(Context)

    const getClassID = () => {
        if (Object.keys(teacher.selectedClasSubjectSyllabus).length != 0) {
            return teacher.selectedClasSubjectSyllabus?.split('|')[0]
        } else {
            return teacher.classesSubjects[0]?.id.split('|')[0]
        }
    }

    const getSubjectID = () => {
        if (Object.keys(teacher.selectedClasSubjectSyllabus).length != 0) {
            return teacher.selectedClasSubjectSyllabus?.split('|')[1]
        } else {
            return teacher.classesSubjects[0]?.id.split('|')[1]
        }
    }

    return (
        <Card>
            <Form className="mx-2">
                <Form.Select onChange={(e) => teacher.setSelectedClasSubjectSyllabus(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={teacher.selectedClasSubjectSyllabus || teacher.classesSubjects[0]?.id} aria-label="Default select example">
                    {teacher.classesSubjects.map(classub =>
                        <option key={'cs' + classub.id} value={classub.id}>{classub.className + ' / ' + classub.subjectName}</option>
                    )}
                </Form.Select>
            </Form>
            <Outlet />
            {!moduleID &&
                <>
                    <SchoolBook classID={getClassID()} subjectID={getSubjectID()} />
                    <ModulesList classID={getClassID()} subjectID={getSubjectID()} />
                </>
            }
        </Card>
    )
});

export default TeacherModulesTableNM;