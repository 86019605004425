import { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { createScheduleGrid, createTact, fetchScheduleGrids, fetchTacts, modyfyScheduleGrid, updateTact } from '../../../http/adminApi';
import { Card, Form, Input, InputNumber, Row, Table, Col, DatePicker } from 'antd';
import { FaBan, FaEdit, FaPlus, FaSave } from 'react-icons/fa';
import { dateDMFormat } from '../../utilites';
import dayjs from 'dayjs'

import MyDatePicker from '../../MyDatePicker';
import { Button } from 'react-bootstrap';
import MyInput from '../../MyInput';


type DataType = {
    id?: number;
    name?: string;
    teachingBegin?: string | null;
    teachingEnd?: string | null;
    testingBegin?: string | null;
    testingEnd?: string | null;
    researchingBegin?: string | null;
    researchingEnd?: string | null;
}

const onChange = async (id: number, name: string, data: dayjs.Dayjs | string | undefined) => {
    var data1 = undefined;

     switch (typeof(data)) {
        case 'string' : data1 = data; break;
        case 'object' : data1 = data.format(); break;
        default : data1 = undefined;
    }
    console.log(id, name, data1);
    updateTact({tactID: id, [name]:data1});

}

const AdminRefTacts = observer(() => {

    const [data, setData] = useState<DataType[]>([]);

    //    const { admin } = useContext(Context)

    useEffect(() => {
        loader();
    }, [])

    const loader = async () => {
        const data = await fetchTacts();
        setData(data);
    }

    const addTact = async () => {
        const newData: DataType = {
            // key: id,
            name: "",
            teachingBegin: null,
            teachingEnd: null,
            testingBegin: null,
            testingEnd: null,
            researchingBegin: null,
            researchingEnd: null,

        };
        const new_data = await createTact();
        if (new_data) setData([...data, new_data]);
//        setData([...data, newData]);
    };

    /*     const save = async (id) => {
            try {
                const row = await form.validateFields();
                const newData = [...data];
                const index = newData.findIndex((item) => id === item.id);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...row,
                    });
                    setData(newData);
                    setEditingKey('');
                } else {
                    newData.push(row);
                    setData(newData);
                    setEditingKey('');
                }
            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
        };
     */
    /*     const cancel = () => {
            setEditingKey('');
        }; */

    return (
        <Card>
            <Row>
                <Col span={4}>Название</Col>
                <Col span={6}>Учебное погружение</Col>
                <Col span={6}>Зачетная неделя</Col>
                <Col span={6}>Исследовательское погружение</Col>
                <Col span={1}><Button onClick={() => addTact()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></Col>
            </Row>
            {data.map((item) => {
                const id = item?.id ? item.id : 0;
                return (
                    <Row key={id}>
                        <Col span={4}><MyInput id={id} name='name' defaultValue={item.name} onChange={onChange} placeholder='Новый такт'/></Col>
                        <Col span={2}><MyDatePicker id={id} name="teachingBegin" defaultValue={dayjs(item.teachingBegin)} onChange={onChange} /></Col>
                        <Col span={2}><MyDatePicker id={id} name="teachingEnd" defaultValue={dayjs(item.teachingEnd)} onChange={onChange} /></Col>
                        <Col span={2}></Col>
                        <Col span={2}><MyDatePicker id={id} name="testingBegin" defaultValue={dayjs(item.testingBegin)} onChange={onChange} /></Col>
                        <Col span={2}><MyDatePicker id={id} name="testingEnd" defaultValue={dayjs(item.testingEnd)} onChange={onChange} /></Col>
                        <Col span={2}></Col>
                        <Col span={2}><MyDatePicker id={id} name="researchingBegin" defaultValue={dayjs(item.researchingBegin)} onChange={onChange} /></Col>
                        <Col span={2}><MyDatePicker id={id} name="researchingEnd" defaultValue={dayjs(item.researchingEnd)} onChange={onChange} /></Col>
                    </Row>
                )
            })}
        </Card>
    );
});

export default AdminRefTacts;