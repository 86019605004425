import React, { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Button, Container } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { createScheduleGrid, deleteScheduleGrid, fetchScheduleGrids, modyfyScheduleGrid } from '../../../http/adminApi';
import { Popconfirm, Typography, message } from 'antd';
import AdminClass from '../modals/AdminClass';

const { Paragraph } = Typography;

const AdminScheduleGrid = observer(() => {
    const { admin } = useContext(Context)

    const [classVisible, setClassVisible] = useState(false)
    const [classToModal, setClassToModal] = useState({})

    function onClickClass(clas) {
        let classFromClassesList = admin.classes.find(i => i.id == clas.id)
        admin.setSelectedClass(classFromClassesList);
        setClassToModal(classFromClassesList);
        setClassVisible(true);
    }

    useEffect(() => {
        loadData()
    }, [admin.setSelectedSubMenu, admin.selectedSideMenu])

    const loadData = async () => {
        const data = await fetchScheduleGrids()
        admin.setScheduleGrids(data)
    }

    const openGridItems = (grid) => {
        admin.setSelectedScheduleGrid(grid)
        admin.setSelectedSubMenu({ id: 121 })
    }

    const onClickNewGrid = async () => {
        const data = await createScheduleGrid()
        admin.setScheduleGrids([...admin.scheduleGrids, {...data, uses:[]}])
    }

    const changeGridName = async (gridID, name) => {
        const data = await modyfyScheduleGrid(gridID, name)
        admin.setScheduleGrids(admin.scheduleGrids.map(i => i.id === gridID ? { ...i, ['name']: name } : i))
    }

    async function deleteGrid(gridID) {
        const data = await deleteScheduleGrid(gridID)
        if (data > 0) {
            message.success("Удаление успешно!")
            admin.setScheduleGrids(admin.scheduleGrids.filter(i => i.id !== gridID))
        } else {
            message.error("Удаление не удалось!")
        }
    }

    /*     const columns = [
            {
                title: <b>Название сетки расписания</b>,
                dataIndex: 'name',
                key: 'name',
                render: (name) => <Paragraph
                    editable={{
                        tooltip: 'Введите название сетки',
                        onChange: (value) => changeGridName(name, value),
                        // triggerType: 'both',
                    }}
                >
                    {name}
                </Paragraph>,
            },
            {
                title: <b style={{ verticalAlign: 'bottom' }}>Действия<Button onClick={() => onClickNewGrid()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></b>,
                dataIndex: 'id',
                key: 'id',
                render: (grid) => <Button onClick={() => openGridItems(grid)}>Редактирование</Button>,
            },]
     */

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th style={{ verticalAlign: 'bottom' }}>Название сетки расписания</th>
                        <th style={{ verticalAlign: 'bottom' }}>Назначено</th>
                        <th style={{ verticalAlign: 'bottom' }}>Действия</th>
                        <th style={{ verticalAlign: 'bottom' }}><Button onClick={() => onClickNewGrid()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {admin.scheduleGrids.map(grid =>
                        <tr key={grid.id}>
                            <td>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Введите название сетки',
                                        onChange: (value) => changeGridName(grid.id, value),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {grid.name}
                                </Paragraph>
                            </td>
                            <td>
                                {!grid?.uses.length != 0 &&
                                    grid?.uses.map(u =>
                                        <Button onClick={() => onClickClass(u)}>
                                            {u.name}
                                        </Button>
                                    )
                                }
                            </td>
                            <td><Button onClick={() => openGridItems(grid)}>Редактирование</Button></td>
                            <td>
                                {grid?.uses.length == 0 &&
                                    <Popconfirm
                                        title={'Вы уверены что хотите удалить сетку расписания?'}
                                        onConfirm={() => deleteGrid(grid.id)}
                                        onCancel={() => message.warning('Удаление отменено')}
                                        okText="Да"
                                        cancelText="Нет"
                                    >
                                        <Button className='float-end'>
                                            <FaMinus className="d-flex justify-content-between" />
                                        </Button>
                                    </Popconfirm>
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <AdminClass show={classVisible} onHide={() => setClassVisible(false)} clas={classToModal} />
            {/*   <ATable columns={columns} dataSource={admin.scheduleGrids} pagination={{ position: [], }} /> */}
        </Container>
    );
});

export default AdminScheduleGrid;