import { FC, useContext, useEffect } from "react";
import { Context } from "../index";

import { useLoaderData, useNavigate } from "react-router-dom";
import { ADMIN_ROUTE, LOGIN_ROUTE, PARENT_ROUTE, STUDENT_ROUTE, TEACHER_ROUTE, TUTOR_ROUTE } from "../utils/consts";
import Admin from "../pages/Admin";
import Student from "../pages/Student";

export async function loader() {
    console.log('====================================');
    console.log("root loader");
    console.log('====================================');

    return "ok";
}


export const Root: FC = () => {
    const { user } = useContext(Context) as any;

    const navigate = useNavigate();
    // const { check } = useLoaderData() as any;
    console.log('====================================');
    console.log("root", user);
    console.log('====================================');
    useEffect(() => {

        if (user.isAuth) {
//            navigate('/' + user.user.role, { replace: true })
        } else {
//            navigate(LOGIN_ROUTE, { replace: true });
        }

    })

    return (
        <>R</>
    )
}

