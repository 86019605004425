import React, { useEffect, useContext, useState } from 'react';
import { observer } from "mobx-react-lite";
/* import { Button, Container, Form } from 'react-bootstrap';
import { fetchStarSData } from '../../../http/studentApi';
import RadarChart from '../../utilites/star/RadarChart'; */
import { Context } from '../..';
import { Card, Col, message, Row, Slider } from 'antd';
import { fetchStarSubData, modyfyStarSubData } from '../../http/teacherApi';
//import TeacherStudentSubjectStarTips from '../../utilites/star/StarTips';
import EditStar from '../utilites/star/EditStar';
import { fetchTipsData } from '../../http/adminApi';
import SelectClassStudent from './SelectClassStudent';
import SkillStars from '../admin/stars/SkillStars';
import { fetchSkillTacts } from '../../http/parentApi';

export const TutorSubjectStarLoader = async () => {
    console.log("TutorSubjectStarLoader");
    return null;
 }

const TutorSubjectStar = observer(() => {
    const { admin } = useContext(Context)
    const [skillTacts, setSkillTacts] = useState([]);


    useEffect(() => {
        loadData()
    }, [admin.selectedStudent])

    const chartSize = 450;
    const numberOfScale = 5;

    const [starS, setStarS] = useState([])

    //  [{ 'Самооценка': 4, 'Самоконтроль': 3, 'Самоорганизация': 3, 'Соц-эмоциональный интеллект': 2, 'Стратегическое мышление': 3, 'Креативное мышление': 3 },
    //  { 'Самооценка': 4, 'Самоконтроль': 3, 'Самоорганизация': 2, 'Соц-эмоциональный интеллект': 3, 'Стратегическое мышление': 2, 'Креативное мышление': 2 }])

    const [tipsDataName, setTipsDataName] = useState('')
    const [tipsDataChoice, setTipsDataChoice] = useState(0)
    const [stateSaveButton, setStateSaveButton] = useState(true)
    const [tipsTextData, setTipsTextData] = useState({})

    const loadData = async () => {
        console.log("loadData", admin.selectedStudent?.id);
        //const dataS = await fetchStarSData()
        if (admin.selectedStudent?.id) {
            const dataS = await fetchStarSubData(admin.selectedStudent?.id)
            setStarS(dataS)
            const tips = await fetchTipsData('tutor')
            setTipsTextData(tips)
            const skillTacts = await fetchSkillTacts(admin.selectedStudent?.id);
            setSkillTacts(skillTacts);

        } else {
            setStarS([])
            setTipsTextData({})
        }
    }

    const handleChangeSkillTacts = async (value) => {
        var data = [[], []];
        if (admin.selectedStudent?.id) {
            data = await fetchStarSubData(admin.selectedStudent?.id, value)
        }

        setStarS(data);

    };


    const change1 = (name, value) => {
        let star = starS
        star[1][name] = value
        setStarS(JSON.parse(JSON.stringify(star)))
        setTipsDataName(name)
        setTipsDataChoice(value)
        setStateSaveButton(false)
        //    console.log("starS",starS);
    }

    const saveData = async () => {
        try {
            const formData = new FormData()
            formData.append('studentID', admin.selectedStudent?.id)
            formData.append('starData', JSON.stringify(starS[0]))
            console.log(starS);
            modyfyStarSubData(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                    loadData()
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    }

    return (<>
        <SelectClassStudent />
        <SkillStars
            key = {"key"+admin.selectedStudent?.id}
            studentID = {admin.selectedStudent?.id}
            skillTacts = {skillTacts}
            handleChangeSkillTacts = {handleChangeSkillTacts}
            saveData = {saveData}
            starS={starS}
            tipsTextData = {tipsTextData}
            editable={true}
        />
{/*         <Card title="Индивидуальный план развития">
            <EditStar
                //header={}
                starS={starS}
                setStarS={setStarS}
                tipsTextData={tipsTextData}
                starLayer={0}
                saveData={saveData}
                greenDesc='- самооценка студента'
                yellowDesc='- оценка тьютора'
            />
        </Card> */}
    </>
    );
});

export default TutorSubjectStar;