import React, { ReactNode, useEffect, useState } from 'react';
import { AccordionBody, Form } from "react-bootstrap";
import { Button, Card, Tree, GetProps, Row, Col } from 'antd';
import { $authHost } from '../http';
import { fetchDescriptions, fetchModules, fetchRoutes, fetchSilabus, fetchSilabusData, fetchThemes } from '../http/teacherApi';
import Editor from '../components/utilites/quill/EditorBlur';
import BasicTable from '../components/utilites/old/BasicTable';
import { useLoaderData } from 'react-router-dom';
import HTMLViewer from '../components/utilites/HTMLViewer';
import dayjs, { Dayjs } from 'dayjs'
import { FaPlus } from 'react-icons/fa';
import ModuleCard from '../components/ModuleCard';
import { BiPlus } from 'react-icons/bi';
import { Accordion } from '../components/Accordion/Accordion';
//import Accordion from 'react-bootstrap/Accordion';
dayjs.locale('ru');
//import DatePicker from 'react-date-picker';
//import Calendar from 'react-calendar';
//import 'react-date-picker/dist/DatePicker.css';
//import 'react-calendar/dist/Calendar.css';

const AddButton = ({ key, name, onClick }: any) => {
  return {
    title: <Button size='small' onClick={onClick} >
      <BiPlus className="d-flex justify-content-between" />
      {name}
    </Button>,
    key: `${key}-0`,
    selectable: false,
    isLeaf: true,
  }
}
const onClick = (key: any) => {
  console.log("on click", key);
}

export const testLoader = async () => {
  const classID = 7, subjectID = 28;
  const modules = [];
  const tree = [];

  var data = await fetchSilabusData(classID, subjectID, "2023-9-1", "2023-11-31");

  const mods = await fetchModules(classID, subjectID);
  for (const module of mods) {
    tree.push({
      title: (module.name) ? `${module.name} (${dayjs(module.begin).format('DD MMM')} - ${dayjs(module.end).format('DD MMM')})` : "!",
      key: module.id
    })
  }
  //tree.push(AddButton({ key: `${classID}-${subjectID}`, name: 'Добавить модуль', onClick: onClick(`${classID}-${subjectID}`) }))



  const silabus = await fetchSilabus(classID, subjectID, "2023-9-1", "2023-11-31");

  for (const module of silabus) {
    const themes = [];
    for (const theme of module.themes) {
      const routes = [];
      for (const route of theme.routes) {
        const descriptions = [];
        for (const description of route.descriptions) {

          const key = "description_" + description.id;
          data = data.map((item: any) => {
            const score = description.responses.find((r_item: ResponseItem) => r_item.studentID === item.id)?.score
            if (score)
              return { ...item, [key]: score }
            else
              return { ...item }
          })

          descriptions.push({
            accessor: "description_" + description.id,
            //Header: (description.text) ? () => { return (<HTMLViewer value={description.text} />) } : "Нет описания", id: "description_" + description.id,
            Header: "Описание краткое",
            //style: { background: '#d4ecff', padding: '4px', minHeight: '300px', minWidth: '250px', justifyItems: 'flex-start', position: 'relative', margin: '0px', },
            style: styleDescriptions
          });
        }
        const _route = { Header: (route.name) ? route.name : "!", id: "route-" + route.id, style: { ...styleRoutes, background: route.color } };
        if (descriptions.length > 0) {
          routes.push({ ..._route, columns: descriptions });
        } else {
          routes.push(_route);
        }
      }
      const _theme = { Header: (theme.name) ? `${theme.name} (до ${dayjs(theme.end).format('DD MMM')})` : "Нет темы", id: "theme-" + theme.id, style: styleThemes }

      if (routes.length > 0) {
        themes.push({ ..._theme, columns: routes });
      } else {
        themes.push(_theme);
      }
    }
    modules.push({
      Header: (module.name) ? `${module.name} (${dayjs(module.begin).format('DD MMM')} - ${dayjs(module.end).format('DD MMM')})` : "!", columns: themes, accessor: "module" + module.id, id: "module" + module.id,
      style: styleModules
    });
  }

  //console.log(data);

  return { silabus, data, modules, tree };
}

export const style: React.CSSProperties = { background: '#d4ecff', padding: '4px', minHeight: '300px', minWidth: '250px', justifyItems: 'flex-start', position: 'relative', margin: '0px', };
const styleModules: React.CSSProperties = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleThemes: React.CSSProperties = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleRoutes: React.CSSProperties = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };
const styleDescriptions: React.CSSProperties = { background: '#efefef', padding: '3px', border: 'solid 1px gray'/* , writingMode: 'vertical-rl' */, minHeight: '400px', minWidth: '50px', fontSize: '0.rem', verticalAlign: 'top' };

const { DirectoryTree } = Tree;

interface DataNode {
  title: string | ReactNode;
  key: string | number;
  isLeaf?: boolean;
  children?: DataNode[];
  selectable?: boolean;
}
interface ResponseItem {
  score: string,
  studentID: number
}

interface DescriptionItem {
  id: number;
  index: number;
  text: string;
  responses: ResponseItem[];
}

interface RouteItem {
  id: number;
  index: number;
  name: string;
  color: string;
  descriptions: DescriptionItem[];
}

interface ThemeItem {
  id: number;
  index: number;
  name: string;
  workTypeId: number;
  workTypeName: string;
  routes: RouteItem[];
}

interface ModuleItem {
  id: number;
  index: number;
  name: string;
  themes: ThemeItem[];
}

interface LoadData {
  silabus: ModuleItem[],
  modules: any,
  data: any,
  tree: DataNode[],
}

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;

const initTreeData: DataNode[] = [
  /*   {
      title: 'Модуль 1:', key: '10', children: [
        { title: 'leaf 0-0', key: '0-0-0', isLeaf: true },
        { title: 'leaf 0-1', key: '0-0-1', isLeaf: true },
      ],
    },
    { title: 'Expand to load', key: '11' },
    { title: 'Tree Node', key: '20', isLeaf: true }, */
];

const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

const EmptySelected = () => {
  return (
    <Card >
      Выберете модуль в меню слева
    </Card>
  )

}

const Test = () => {
  const { silabus, modules, data, tree } = useLoaderData() as LoadData;

  //  console.log("test silabus:", silabus, modules);

  const [url, setURL] = useState('')
  const [body, setBody] = useState('')
  const [type, setType] = useState('POST')
  const [answer, setAnswer] = useState('')
  const [treeData, setTreeData] = useState(/* initTreeData */tree);
  const [selected, setSelected] = useState<ReactNode>(EmptySelected());
  //const [modules, setModules] = useState<ModuleItem[]>([]);

  /* useEffect(() => {
    LoadData();
  }, []); */

  const send = async (event: any) => {
    try {
      event.preventDefault();
      let data
      switch (type) {
        case 'POST': data = await $authHost.post(url, { body })
          break
        case 'GET': data = await $authHost.get(url)
          break
        case 'PUT': data = await $authHost.put(url, { body })
          break
        //        case 'DELETE': data = await $authHost.delete(url, { body })
        //          break
        default: { }
      }
      console.log(data);
      setAnswer(JSON.stringify(data))
      //alert(data.data.toString());

    } catch (e) {
      alert(e)
    }
  }
  /* 
    const handleKeyPress = (event:any) => {
      if (event.key === 'Enter') {
        console.log('enter press here! ')
        send(event)
      }
    }
   */

  const key2number = (key: React.Key) => {
    var params: string | number | undefined | string[] = undefined;
    var _type = 'module'

    if (typeof (key) === 'number') {
      return { _type, id: key }
    }
    if (typeof (key) === 'string') {
      params = key.split('-');

      switch (params.length) {
        case 2: _type = 'theme'; break;
        case 3: _type = 'route'; break;
        case 4: _type = 'description'; break;
        default: console.log('default');
      }

      return { _type, id: params[params.length - 1] }
    }
    return undefined
  }

  const onLoadData1 = async ({ key, children }: any) => {
    //var params: number | string | undefined | string[] = undefined;
    var ret = [];
    var tree: DataNode[] = [];
    var isLeaf: boolean = true;

    console.log("load tree", key, children);
    const { _type, id }: any = key2number(key);

    switch (_type) {
      case 'module': ret = await fetchThemes(id); isLeaf = false; break;
      case 'theme': ret = await fetchRoutes(id); isLeaf = false; break;
      case 'route': ret = await fetchDescriptions(id); isLeaf = true; break;
    }

    for (const item of ret) {
      tree.push({
        isLeaf,
        title: (item.name) ? `${item.name} ` : (item.description) ? item.description : item.text,
        key: `${key}-${item.id}`,
      })
    }
    switch (_type) {
      case 'module': tree.push(AddButton({ key, name: 'Добавить тему' })); break;
      case 'theme': tree.push(AddButton({ key, name: 'Добавить умение' })); break;
      case 'route': tree.push(AddButton({ key, name: 'Добавить задание' })); break;
    }

    setTreeData((origin) =>
      updateTreeData(origin, key, tree)
    );

  }

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    console.log('Trigger Select', keys, info);
    const { _type, id }: number | any = key2number(keys[0]);

    switch (_type) {
      //case 'module': setSelected(<ModuleCard key={id} id={id}></ModuleCard>); break;
      //case 'theme': ret = await fetchRoutes(id); isLeaf = false; break;
      //case 'route': ret = await fetchDescriptions(id); isLeaf = true; break;
      default: setSelected(EmptySelected());
    }
  };

  const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
    //console.log('Trigger Expand', keys, info);
  };

  /*  type ValuePiece = Date | null;
   type Value = ValuePiece | [ValuePiece, ValuePiece]; */
  const [valueDate, onChangeDate] = useState<string>('2014-08-01');
  /* const [startDate, setStartDate] = useState(new Date());
  */
  return (
    <>
      <Card>
        <div style={{ width: '250px', border: '1px black', display: 'flex', flexBasis: '100px' }}>
          <input aria-label="Date" type="date" /* defaultValue={valueDate}  */ value={valueDate} onChange={(e) => {
            const newDate = dayjs(e.target.value).format('YYYY-MM-DD');
            console.log(newDate);
            onChangeDate(newDate)
          }
          } />
          {/* <Calendar onChange={onChangeDate} value={valueDate} maxDetail='month'/> */}

        </div>
        <hr />
        {/*         <table>
          <thead>
            <tr>
              <th>Модуль</th>
              {silabus.map((column) => (
                <th colSpan={column.themes.length}>
                  {column.name}
                </th>
              ))}
            </tr>
            <tr>
              <th>Темы</th>
                {silabus.map((module) => {
                  const ret = module.themes.map((theme) => {
                    return (<th colSpan={theme.routes.length}>{theme.name}</th>)
                  })
                  return ret
                })}
            </tr>
            <tr>
              <th>Умения</th>
                {silabus.map((module) => {
                  const ret = module.themes.map((theme) => {
                    const ret = theme.routes.map((route) => {
                      return (<th colSpan={route.descriptions.length}>{route.name}</th>)
                    })
                    return ret
                  })
                  return ret
                })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                2
              </td>
            </tr>
          </tbody>
        </table> */}
         <BasicTable silabus={modules} data={data} /> 

        <hr />
        {/* <Accordion list={[{a:'1 вопрос', q:'1 ответ'},{q:'2 вопрос', a:<Accordion list={[{a:'3 вопрос', q:'3 ответ'},{a:'4 вопрос', q:'4 ответ'}]}/>}]}/> */}
      </Card>

      <Card> {/* directory tree */}
{/*         <Row gutter={[8, 8]}>
          <Col span={12}>
            <DirectoryTree loadData={onLoadData1} treeData={treeData} onSelect={onSelect} onExpand={onExpand} showIcon={false} />
          </Col>
          <Col span={12}>
            {selected}
          </Col>
        </Row> */}
      </Card>
      <Card> {/* форма запросов */}

        <Form className="d-flex flex-column">
          <Form.Select onChange={(e) => setType(e.target.value)} className="mt-3 bg-transparent" bsPrefix="form-select" defaultValue={type}>
            <option key={'POST'} value={'POST'}>{'POST'}</option>
            <option key={'GET'} value={'GET'}>{'GET'}</option>
            <option key={'PUT'} value={'PUT'}>{'PUT'}</option>
            <option key={'DELETE'} value={'DELETE'}>{'DELETE'}</option>
          </Form.Select>

          <Form.Control
            className="mt-3"
            placeholder="URL:"
            value={url}
            onChange={e => setURL(e.target.value)}
          />

          <Form.Control
            as="textarea"
            className="mt-3"
            placeholder="BODY:"
            rows={5}
            value={body}
            onChange={e => setBody(e.target.value)}
          />

          <Button
            className='mt-3 align-self-end'
            /* variant={'outline-success'} */
            onClick={send}
          >
            Отправить
          </Button>

          <Form.Control as="textarea"
            className="mt-3"
            placeholder="SERVER ANSWER:"
            rows={5}
            value={answer}
            onChange={e => { }}
          />
        </Form>
      </Card>
    </>
  );
};

export default Test;
