import React, { useEffect, useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { fetchSilabus, fetchSilabusM } from '../../../http/teacherApi'
import HTMLViewer from '../HTMLViewer'
import dayjs from 'dayjs'

const styleModules = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleThemes = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleRoutes = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };
const styleDescriptions/* : React.CSSProperties */ = { background: '#efefef', padding: '3px', border: 'solid 2px gray'/* , writingMode: 'vertical-rl' */, minHeight: '400px', minWidth: '50px', fontSize: '0.rem', verticalAlign: 'top' };



export const loadSilabus = async (params) => {
  const { classID, subjectID, begin, end, moduleID } = params;
  var { data } = params;
  console.log("loadSilabus data", data);

  //const silabus = await fetchSilabus(classID, subjectID, begin, end);
  const silabus = await fetchSilabusM(classID, subjectID, moduleID);
  const modules = [];

  for (const module of silabus) {
    const themes = [];
    for (const theme of module.themes) {
      const routes = [];
      for (const route of theme.routes) {
        const descriptions = [];
        for (const description of route.descriptions) {

          const key = "description_" + description.id;
          if (data) {
            data = data.map((item) => {
              const score = description.responses.find((r_item) => r_item.studentID === item.id)?.score
              if (score)
                return { ...item, [key]: score }
              else
                return { ...item }
            })
          }

          descriptions.push({
            accessor: "description_" + description.id,
            Header: (description.description) ? () => { return (<HTMLViewer value={description.description} />) } : "Нет описания", id: "description_" + description.id,
            //style: { background: '#d4ecff', padding: '4px', minHeight: '300px', minWidth: '250px', justifyItems: 'flex-start', position: 'relative', margin: '0px', },
            style: styleDescriptions
          });
        }
        const _route = { Header: (route.name) ? route.name : "!", id: "route-" + route.id, style: { ...styleRoutes, background: route.color } };
        if (descriptions.length > 0) {
          routes.push({ ..._route, columns: descriptions });
        } else {
          routes.push(_route);
        }
      }
      const _to = dayjs(theme.end).isValid() ? `(до ${dayjs(theme.end).format('DD MMM')})` : '';
      const _theme = { Header: (theme.name) ? `${theme.name} ${_to}` : "Нет темы", id: "theme-" + theme.id, style: styleThemes }

      if (routes.length > 0) {
        themes.push({ ..._theme, columns: routes });
      } else {
        themes.push(_theme);
      }
    }
    modules.push({
      Header: (module.name) ? `${module.name} (${dayjs(module.begin).format('DD MMM')} - ${dayjs(module.end).format('DD MMM')})` : "!", columns: themes, accessor: "module" + module.id, id: "module" + module.id,
      style: styleModules
    });
  }

  return { modules }

}

const MOCK_DATA = [
  {
    id: 'id',
    name: 'name',
    skill: 'skill',
    description_1988: 'test'
  }
]

const COLUMNS = [{
  Header: ' ',
  columns: [{
    Header: 'N п/п',
    accessor: 'id'
  }, {
    Header: 'ФИ студента',
    accessor: 'name',
    style: styleModules,
  }]
}, {
  Header: 'Предмет:',
  style: { background: '#bfbfbf', padding: '4px', border: 'solid 1px gray' },
  columns: [{
    Header: 'Модуль',
    style: styleModules,
    columns: [{
      Header: 'Темы',
      style: styleThemes,
      columns: [{
        Header: 'Умения',
        style: styleRoutes,
        columns: [{
          Header: 'Уровень',
          style: styleRoutes,
          /* columns: [{
            Header: 'Оценивание',
            style: styleRoutes,
            columns: [{
              Header: 'Само-а'
            }, {
              Header: '/оц. пед.',
            }]
          }] */
        }]
      }]
    }]
  }]
},
]

//export type DensityState = 'sm' | 'md' | 'lg'

const SilabusTable = ({ silabus, data }) => {
  //const [density, setDensity] = React.useState<DensityState>('md')

  //console.log("BASICTABLE->", silabus);

  const columns = useMemo(() => [...COLUMNS, {
    Header: 'История / 7 класс', columns: silabus, style: { background: '#ffffff', padding: '4px', border: 'solid 1px gray' },
  }], [])  //useMemo(() => COLUMNS, [])
  //const data = MOCK_DATA //useMemo(() => MOCK_DATA, [])


  //const tableInstance = useTable({ columns: [{ Header: "empty" }], data: [{}] });

  //columns.push({ Header: 'История', columns: silabus });
  //console.log(columns);
  const tableInstance = useTable({
    columns,
    data,
    /* state: {
      density, //passing the density state to the table, TS is still happy :)
    }, */
  })



  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = tableInstance

  //console.log("rows->",rows);

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps([{
                  style: column.style
                }])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()} style={styleDescriptions}>
                  {cell.render('Cell')}</td>
              })}
            </tr>
          )
        })
        }
      </tbody>
    </table>
  )
}

export default SilabusTable
