import { message } from "antd";
import { $authHost } from "./index";

export const createEvent = async (date, end) => {
    const { data } = await $authHost.post('api/services/event', { date, end })
    return data
}

export const deleteEvent = async (eventID) => {
    const { data } = await $authHost.delete('api/services/event/' + eventID)
    return data
}

export const modyfyEvent = async (date, name, eventID, end) => {
    const { data } = await $authHost.put('api/services/event', { date, end, name, eventID })
    return data
}

export const fetchEvents = async () => {
    try {
        const { data } = await $authHost.get('api/services/events')
        return data
    } catch {
        return null;
    }
}

export const createColor = async () => {
    const { data } = await $authHost.post('api/services/color')
    return data
}

export const deleteColor = async (colorID) => {
    const { data } = await $authHost.delete('api/services/color/' + colorID)
    return data
}

export const modyfyColor = async (name, color, colorID) => {
    const { data } = await $authHost.put('api/services/color', { name, color, colorID })
    return data
}

export const fetchColors = async () => {
    const { data } = await $authHost.get('api/services/colors')
    return data
}

export const createBranch = async () => {
    const { data } = await $authHost.post('api/services/branch')
    return data
}

export const deleteBranch = async (branchID) => {
    const { data } = await $authHost.delete('api/services/branch/' + branchID)
    return data
}

export const modyfyBranch = async (name, branchID) => {
    const { data } = await $authHost.put('api/services/branch', { name, branchID })
    return data
}

export const fetchBranches = async () => {
    const { data } = await $authHost.get('api/services/branches')
    return data
}

export const createLessonType = async () => {
    const { data } = await $authHost.post('api/services/lessontype')
    return data
}

export const deleteLessonType = async (lessonTypeID) => {
    const { data } = await $authHost.delete('api/services/lessontype/' + lessonTypeID)
    return data
}

export const modyfyLessonType = async (name, lessonTypeID) => {
    const { data } = await $authHost.put('api/services/lessontype', { name, lessonTypeID })
    return data
}

export const fetchLessonTypes = async () => {
    const { data } = await $authHost.get('api/services/lessontypes')
    return data
}

export const createWorkType = async () => {
    const { data } = await $authHost.post('api/services/worktype')
    return data
}

export const deleteWorkType = async (workTypeID) => {
    const { data } = await $authHost.delete('api/services/worktype/' + workTypeID)
    return data
}

export const modyfyWorkType = async (name, workTypeID) => {
    const { data } = await $authHost.put('api/services/worktype', { name, workTypeID })
    return data
}

export const fetchWorkTypes = async (classubID) => {
    if (classubID) {
        const { data } = await $authHost.get('api/services/worktypes?classubID=' + classubID)
        return data
    } else {
        const { data } = await $authHost.get('api/services/worktypes')
        return data
    }
}

export const createSubject = async () => {
    const { data } = await $authHost.post('api/services/subject')
    return data
}

export const deleteSubject = async (subjectID) => {
    const { data } = await $authHost.delete('api/services/subject/' + subjectID)
    return data
}

export const modyfySubject = async (name, subjectID) => {
    const { data } = await $authHost.put('api/services/subject', { name, subjectID })
    return data
}

export const fetchSubjects = async () => {
    try {
        const { data } = await $authHost.get('api/services/subjects')
        return data
    } catch {
        return []
    }
}

export const createSSubject = async () => {
    const { data } = await $authHost.post('api/services/starsubject')
    return data
}

export const deleteSSubject = async (id) => {
    const { data } = await $authHost.delete('api/services/starsubject/' + id)
    return data
}

export const modyfySSubject = async (name, id) => {
    const { data } = await $authHost.put('api/services/starsubject', { name, id })
    return data
}

export const fetchSSubjects = async () => {
    const { data } = await $authHost.get('api/services/starsubjects')
    return data
}

export const modyfySSubjectDesc = async (description, id, layer, key, rayID) => {
    const { data } = await $authHost.put('api/services/starsubjectdesc', { description, id, layer, key, rayID })
    return data
}

export const fetchTipsData = async (key) => {
    const { data } = await $authHost.get('api/services/starsubjectdescriptions?key=' + key)
    return data
}

export const fetchSSubjectsDesc = async (layer, key, rayID) => {
    const { data } = await $authHost.get('api/services/starsubjectsdesc?rayID=' + rayID + '&key=' + key + '&layer=' + layer)
    return data
}


export const createClass = async (name, leader, teacher, scheduleGrid, stageID, subjects) => {
    const { data } = await $authHost.post('api/services/class', { name, leader, teacher, scheduleGrid, stageID, subjects })
    return data
}

export const deleteClass = async (classID) => {
    const { data } = await $authHost.delete('api/services/class/' + classID)
    return data
}

export const modyfyClass = async (name, leader, teacher, scheduleGrid, classID, stageID, subjects) => {
    const { data } = await $authHost.put('api/services/class', { name, leader, teacher, scheduleGrid, classID, stageID, subjects })
    return data
}

export const fetchClasses = async () => {
    const { data } = await $authHost.get('api/services/classes')
    return data
}

export const modyfySchedule = async (id, subjectID, day, intID, subjectName, classID) => {
    const { data } = await $authHost.put('api/services/schedule', { id, subjectID, day, intID, subjectName, classID })
    return data
}

export const fetchSchedule = async (classID, gridID) => {
    let query
    if (gridID) {
        query = 'api/services/schedule/' + classID + '?gridID=' + gridID
    } else {
        query = 'api/services/schedule/' + classID
    }
    if (query) {
        const { data } = await $authHost.get(query)
        return data
    } else return []
}

export const fetchScheduleGrid = async (classID) => {
    const { data } = await $authHost.get('api/services/schedulegriditemsbyclass/' + classID)
    return data
}

export const createScheduleGrid = async () => {
    const { data } = await $authHost.post('api/services/schedulegrid')
    return data
}

export const modyfyScheduleGrid = async (schedulegridID, name) => {
    const { data } = await $authHost.put('api/services/schedulegrid', { name, schedulegridID })
    return data
}

export const deleteScheduleGrid = async (schedulegridID) => {
    try {
        const { data } = await $authHost.delete('api/services/schedulegrid/' + schedulegridID)
        return data
    } catch (error) {
        console.log(error.response?.data?.message);
        return null
    }
}

export const fetchScheduleGrids = async () => {
    const { data } = await $authHost.get('api/services/schedulegrids')
    return data
}

export const fetchScheduleGridItems = async (schedulegridID) => {
    const { data } = await $authHost.get('api/services/schedulegriditems/' + schedulegridID)
    return data
}

export const fetchScheduleGridItemsByTeacher = async () => {
    const { data } = await $authHost.get('api/services/schedulegriditemsbyteacher/')
    return data
}

export const createScheduleGridItem = async (schedulegridID) => {
    const { data } = await $authHost.post('api/services/schedulegriditem/' + schedulegridID)
    return data
}

export const modyfyScheduleGridItem = async (schedulegriditemID, begin, end) => {
    const { data } = await $authHost.put('api/services/schedulegriditem/' + schedulegriditemID, { begin, end })
    return data
}

export const deleteScheduleGridItem = async (schedulegriditemID) => {
    const { data } = await $authHost.delete('api/services/schedulegriditem/' + schedulegriditemID)
    return data
}

export const userList = async (userRole) => {
    try {
        const { data } = await $authHost.get('api/user/users?role=' + userRole)
        return data
    } catch (error) {
        console.log(error.response?.data?.message);
        return [];
    }
}

export const delUser = async (userID) => {
    try {
        const { data } = await $authHost.delete('api/user/' + userID)
        return data
    } catch (error) {
        console.log(error.response?.data?.message);
    }
}

export const studentsByClassList = async (classID) => {
    try {
        const { data } = await $authHost.get('api/user/studentsbyclass?classID=' + classID)
        return data
    } catch (error) {
        console.log(error.response?.data?.message);
    }
}

export const createUser = async (user) => {
    const { data } = await $authHost.post('api/user/create', user)
    return data
}

export const modifyUser = async (user) => {
    const { data } = await $authHost.put('api/user/modify/' + user.get('id'), user)
    return data
}

export const deleteFile = async (file) => {
    const { data } = await $authHost.delete('api/file/' + file.uid)
    return data
}

export const createCalendarLocation = async () => {
    const { data } = await $authHost.post('api/admin/calendarlocation')
    return data
}

export const deleteCalendarLocation = async (calendarLocationID) => {
    const { data } = await $authHost.delete('api/admin/calendarlocation/' + calendarLocationID)
    return data
}

export const modyfyCalendarLocation = async (name, calendarLocationID) => {
    const { data } = await $authHost.put('api/admin/calendarlocation', { name, calendarLocationID })
    return data
}

export const fetchCalendarLocations = async () => {
    const { data } = await $authHost.get('api/admin/calendarlocations')
    return data
}

export const createCalendarEventType = async () => {
    const { data } = await $authHost.post('api/admin/calendareventtype')
    return data
}

export const deleteCalendarEventType = async (eventTypeID) => {
    const { data } = await $authHost.delete('api/admin/calendareventtype/' + eventTypeID)
    return data
}

export const modyfyCalendarEventType = async (name, color, eventTypeID, isWeek, isParent) => {
    const { data } = await $authHost.put('api/admin/calendareventtype', { name, color, eventTypeID, isWeek, isParent })
    return data
}

export const fetchCalendarEventTypes = async () => {
    const { data } = await $authHost.get('api/admin/calendareventtypes')
    return data
}

export const fetchResponsible = async () => {
    const { data } = await $authHost.get('api/services/responsible')
    return data
}

export const modyfyResponsibleDirector = async (userID) => {
    const { data } = await $authHost.put('api/services/responsibledirector', { userID })
    return data
}

export const modyfyResponsibleStage = async (userID, stageID, name) => {
    const { data } = await $authHost.put('api/services/responsiblestage', { userID, stageID, name })
    return data
}

export const createResponsibleStage = async () => {
    const { data } = await $authHost.post('api/services/responsiblestage')
    return data
}

export const deleteResponsibleStage = async (stageID) => {
    const { data } = await $authHost.delete('api/services/responsiblestage/' + stageID)
    return data
}

export const fetchClassSubjects = async (classID) => {
    const { data } = await $authHost.get('api/services/classsubjects/' + classID)
    return data
}

export const modyfyClassSubjects = async (savedata) => {
    const { data } = await $authHost.put('api/services/classsubjects/' + savedata.classID, savedata)
    return data
}

export const fetchParentVisits = async () => {
    const { data } = await $authHost.get('api/admin/parentvisits')
    return data
}

export const createVisitInterval = async (params/* intervalID, studentID, parentID, begin, end */) => {
    const { data } = await $authHost.post('api/admin/visitinterval', params/* { intervalID, studentID, parentID, begin, end } */)
    return data
}


export const deleteVisitInterval = async (intervalID) => {
    const { data } = await $authHost.delete('api/admin/visitinterval/' + intervalID)
    return data
}


export const fetchParentIK = async () => {
    const { data } = await $authHost.get('api/admin/parentik')
    return data
}

export const createMKInterval = async (intervalID, studentID, carers, begin, end) => {
    const { data } = await $authHost.post('api/admin/mkinterval', { intervalID, studentID, carers, begin, end })
    return data
}

export const deleteMKInterval = async (intervalID) => {
    const { data } = await $authHost.delete('api/admin/mkinterval/' + intervalID)
    return data
}

export const fetchTacts = async () => {
    try {
        const { data } = await $authHost.get('api/admin/tacts')
        return data;
    } catch (error) {
        return null;
    }
}

export const createTact = async () => {
    try {
        const { data } = await $authHost.post('api/admin/tact')
        return data;
    } catch (error) {
        message.error("Ошибка создания такта");
        return null;
    }
}

export const updateTact = async (obj) => {
    try {
        const { data } = await $authHost.put('api/admin/tact/', obj)
        return data;
    } catch (error) {
        message.error("Ошибка обновления данных такта");
        return null;
    }
}

export const fetchSilabus = async (params) => {
    try {
        const { data } = await $authHost.get('api/admin/silabus?' + params)
        return data
    } catch (error) {
        return null;
    }
}

export const getPermissionCreateTact = async (studentID) => {
    try {
        const { data } = await $authHost.get('api/admin/getPermissionCreateTact?studentID=' + studentID)
        return data
    } catch {
        message.error("Ошибка получения разрешения на создание записи такта");
        return null;
    }
}

export const setPermissionCreateTact = async (studentID) => {
    try {
        const { data } = await $authHost.post('api/admin/getPermissionCreateTact?studentID=' + studentID)
        return data
    } catch {
        message.error("Ошибка записи разрешения на создание записи такта");
        return null;
    }
}
