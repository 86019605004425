import React, { useEffect, useMemo, useState } from 'react'
import { useTable } from 'react-table'
//import './BasicTable.css'
import { fetchSilabus } from '../../../http/teacherApi'
import { observer } from 'mobx-react-lite'
import HTMLViewer from '../HTMLViewer'
import { style } from '../../../pages/Test'

//import MOCK_DATA from './MOCK_DATA.json'
//import {COLUMNS} from './Columns'

const styleModules = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleThemes = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleRoutes = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };


const MOCK_DATA = [
  {
    id: 'id',
    name: 'name',
    skill: 'skill',
    description_1988:'test' 
  }
]

const COLUMNS = [{
  Header: ' ',
  columns: [{
    Header: 'N п/п',
    accessor: 'id'
  }, {
    Header: 'ФИ студента',
    accessor: 'name',
    style: styleModules,
  }]
}, {
  Header: 'Предмет:',
  style: { background: '#bfbfbf', padding: '4px', border: 'solid 1px gray' },
  columns: [{
    Header: 'Модуль',
    style: styleModules,
    columns: [{
      Header: 'Темы',
      style: styleThemes,
      columns: [{
        Header: 'Умения',
        style: styleRoutes,
        columns: [{
          Header: 'Уровень',
          style: styleRoutes,
          /* columns: [{
            Header: 'Оценивание'
          }] */
        }]
      }]
    }]
  }]
},
]

//export type DensityState = 'sm' | 'md' | 'lg'

const BasicTable = ({ silabus, data }) => {
  //const [density, setDensity] = React.useState<DensityState>('md')

  //console.log("BASICTABLE->", silabus);

  const columns = useMemo(() => [...COLUMNS, { Header: 'История / 7 класс', columns: silabus,   style: { background: '#ffffff', padding: '4px', border: 'solid 1px gray' },
  }], [])  //useMemo(() => COLUMNS, [])
  //const data = MOCK_DATA //useMemo(() => MOCK_DATA, [])


  //const tableInstance = useTable({ columns: [{ Header: "empty" }], data: [{}] });

  //columns.push({ Header: 'История', columns: silabus });
  //console.log(columns);
  const tableInstance = useTable({
    columns,
    data,
    /* state: {
      density, //passing the density state to the table, TS is still happy :)
    }, */
  })

  

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = tableInstance

  //console.log("rows->",rows);

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps([{
                  style: column.style
                }])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>
                  {cell.render('Cell')}</td>
              })}
            </tr>
          )
        })
        }
      </tbody>
    </table>
  )
}

export default BasicTable
