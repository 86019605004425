import { Button, Card, Col, DatePicker, Row } from "antd";
import { Typography } from 'antd';
import { useEffect, useState } from "react";
import { BiAddToQueue, BiCommentAdd, BiDotsHorizontal, BiPlus, BiSolidAddToQueue, BiTrash } from "react-icons/bi";
import { createDescription, deleteDescription, fetchDescriptions, modyfyDescriptionO, oneDescription } from "../../http/teacherApi";
import dayjs from 'dayjs'
import Editor from "../utilites/quill/EditorBlur";
import FilesUpload from "../utilites/FilesUpload";
import { Form, redirect, useLoaderData } from "react-router-dom";

const { Paragraph } = Typography;
dayjs.locale('ru')

interface DescriptyonType {
    id: number,
    index?: string,
    description?: string,
    text?: string,
    fileList?: [],
}

export async function loaderDescriptionCardList({ params }: any) {
    console.log("DescriptionCardList", params);

    const { routeID } = params;
    if (routeID) {
        const descriptions = await fetchDescriptions(routeID);

        return { descriptions }
    }
    return {};
}

export async function createDescriptionCardList({ params }: any) {
    const { routeID } = params;
    const ret = await createDescription(routeID);
    console.log("new DescriptionID:", ret);
    return redirect('../' + ret);
}


export async function destroyDescriptionCardList({ params, request }: any) {
    console.log("destroyDescriptionCard", params, request);
    const { themeID, routeID, moduleID, descriptionID } = params
    if (descriptionID) {
        await deleteDescription(descriptionID);

    }
    return redirect(`../../${moduleID}/${themeID}/${routeID}`);
}


export async function actionDescriptionCardList({ params, request }: any) {
    return null
}


const DescriptionCardList = (/* { id }: number | any */) => {

    const { descriptionID, descriptions } = useLoaderData() as any;

    const [module, setModule] = useState<DescriptyonType>({ id: 0 });

    /*     useEffect(() => {
            //load(id);
            setModule(description ? description : {});
        }, [description]); */

    const load = async (id: any) => {
        const ret = await oneDescription(id);
        setModule(ret);
    }

    const change = async (value: any) => {
        console.log("ch idx", value, descriptionID);

//        setModule({ ...module, ...value });
//        modyfyDescriptionO({ descriptionID: module.id, ...value });
    }

    return (
        <>
            {descriptions.map((description:DescriptyonType) => {
                return (
                    <Card title={'Задание: ' + description.description} key={'description' + description.id}>
                        <Row gutter={[4, 4]}>
                            <Col span={6}>
                                <b>Порядковый номер</b>
                            </Col>
                            <Col span={18}>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Порядковый номер',
                                        onChange: (value) => change({ index: value }),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {String(description.index)}
                                </Paragraph>
                            </Col>
                            <Col span={6}>
                                <b>Дидактика</b>
                            </Col>
                            <Col span={18}>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Введите название',
                                        onChange: (value) => change({ description: value }),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {description.description}
                                </Paragraph>
                            </Col>
                            <Col span={24}>
                                <b>Задание урока</b>
                            </Col>
                            <Col span={18}>

                                <Editor
                                    data={description.text}
                                    /* idToSave={description.id} */
                                    funcToSave={(val: any) => change({ text: val })/* changeText(val, description.id) */}
                                    placeholder='Введите описание'
                                ></Editor>

                                <FilesUpload
                                    fileList={description.fileList}
                                    action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'descriptions'}&id=${module.id}`}
                                />
                            </Col>

                            <Col span={6}>
                            </Col>
                            <Col span={18}>
                                <Form
                                    action={/* String(module1?.id) + */ 'destroy'}
                                    method="post"
                                    onSubmit={(event) => {
                                        console.log(event);

                                        if (
                                            !window.confirm(`Подтвердите удаление`)
                                        ) {
                                            event.preventDefault();
                                        }

                                    }}

                                >
                                    <button className='float-end btn btn-outline-danger'>
                                        <BiTrash className="justify-content-between" />
                                        Удалить задание
                                    </button>
                                </Form>
                            </Col>

                        </Row>
                    </Card>
                )
            })}

        </>
    )
}


export default DescriptionCardList;