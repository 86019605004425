import Styles from './index.module.css';
import { DatePicker } from "antd";
import type { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useRef, useState } from "react";
import { dateDMFormat } from '../utilites';
import type { MouseEventHandler } from "react";

require('dayjs/locale/ru')
dayjs.locale("ru");

type MyDatePickerProps = {
    id: number;
    defaultValue?: Dayjs | null | undefined;
    placeholder?: string;
    mode?: "rows" | "cells";
    status?: "default" | "invalid";
    name: string;
    onChange?: (id: number, name: string, date: Dayjs) => void;
    onClose?: () => void;
};

const MyDatePicker = (props: MyDatePickerProps) => {

    const { id, name, defaultValue, onClose, placeholder } = props;

    const [isOpen, setIsOpen] = useState(false);
    const rootRef = useRef<HTMLDivElement>(null);
    //    console.log(defaultValue);

    const [value, setValue] = useState(dayjs(defaultValue).isValid() ? defaultValue : null);

    const weekFormat = 'DD MMM';
    const customWeekStartEndFormat: DatePickerProps['format'] = (value) =>
        `${dayjs(value).format(weekFormat)}`;


    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        //console.log(name, date, dateString);
        setValue(date);
        props.onChange?.(id, name, date);
        setIsOpen(false);
    };

/*     useEffect(() => {

        const handleClick = (event: MouseEvent) => {
            const { target } = event;
            if (target instanceof Node && !rootRef.current?.contains(target)) {
                isOpen && onClose?.();
                //closeHandler();
                console.log("closeHandler");
                
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };

    }, [isOpen, onClose]); */


    const handlePlaceHolderClick: MouseEventHandler<HTMLDivElement> = () => {
        if (!isOpen) {
            setIsOpen(true);
        };
    }

    return (
        <div
            className={Styles.myDatePickerWrapper}
            ref={rootRef}
            data-is-active={isOpen}
        //data-mode={mode}
        >
{/*             <div
                className={Styles.placeholder}
                //data-status={status}
                //data-selected={!!selected?.value}
                onClick={handlePlaceHolderClick}
                role='button'
                tabIndex={0}
            > */}
{/*                 {!isOpen && value ? dayjs(value).format(dateDMFormat) : placeholder}
                {isOpen && ( */}
                    <DatePicker
                        onChange={onChange}
                        format={customWeekStartEndFormat}
                        defaultValue={value}
                        minDate={dayjs().subtract(1, 'year')}
                        maxDate={dayjs().add(1, 'year')}
                        allowClear={false}
                        suffixIcon={<></>}
                        //size='small'
                        //open={true}
                    //onBlur={() => { setIsOpen(false); props.onClose?.() }}
                    //autoFocus
                    //needConfirm
                    />
                {/* )} */}
           {/*  </div> */}
        </div>

    )
}

export default MyDatePicker;