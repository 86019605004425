import { Button, Card, Col, DatePicker, Row } from "antd";
import { Typography } from 'antd';
import { useEffect, useState } from "react";
import { BiAddToQueue, BiCommentAdd, BiDotsHorizontal, BiPlus, BiSolidAddToQueue, BiTrash } from "react-icons/bi";
import { createModule, deleteModule, fetchModules, modyfyModuleO, oneModule } from "../../http/teacherApi";
import dayjs from 'dayjs'
import { Form, redirect, useLoaderData } from "react-router-dom";
const { Paragraph } = Typography;
dayjs.locale('ru')

export async function destroyModuleCard({ params, request }: any) {
    console.log("destroyModuleCard", params, request);
    const { moduleID } = params
    if (moduleID) {
        await deleteModule(moduleID);

    }
    return redirect('../..');
}

export async function createModuleCard({ params }: any) {
    const { selectedClassSubject } = params;
        const [classID, subjectID] = selectedClassSubject.split('|');
        const ret = await createModule(classID, subjectID);
        console.log("new ID:", ret);
        return redirect('../' + ret);
}

export async function loaderModuleCard({ params }: any) {
    console.log("module card loader", params);

    const { moduleID } = params;
    if (moduleID) {
        const module = await oneModule(moduleID);
        return { module }
    }

    return null
}

export interface ModuleType {
    id: number,
    index?: string,
    name?: string,
    begin?: string | null,
    end?: string,
}

const ModuleCard = (/* params: { id: number | any } */) => {

    const { id, module } = useLoaderData() as any;
    //    console.log("modulecard", params, id, module);

    const [module1, setModule] = useState<ModuleType>({ id: 0 });

    useEffect(() => {
        //if (params) loadModule(params?.id);
        setModule(module);
    }, [module]);

    const loadModule = async (id: any) => {
        const ret = await oneModule(id);
        setModule(ret);
    }

    const change = async (value: any) => {
        console.log("ch idx", value, id);

        setModule({ ...module1, ...value });
        modyfyModuleO({ moduleID: module1.id, ...value })
    }

    return (
        module1 &&
        <Card title={'Модуль: ' + module1?.name} key={'module' + module1?.id}>
            <Row gutter={[4, 4]}>
                <Col span={6}>
                    Порядковый номер
                </Col>
                <Col span={18}>
                    <Paragraph
                        editable={{
                            tooltip: 'Порядковый номер',
                            onChange: (value) => change({ index: value }),
                            triggerType: ['icon', 'text'],
                        }}
                    >
                        {String(module1?.index)}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    Название
                </Col>
                <Col span={18}>
                    <Paragraph
                        editable={{
                            tooltip: 'Введите название',
                            onChange: (value) => change({ name: value }),
                            triggerType: ['icon', 'text'],
                        }}
                    >
                        {module1?.name}
                    </Paragraph>
                </Col>
                {/*<Col span={6}>
                    Начало
                </Col>
                 <Col span={18}>
                    <DatePicker name="begin" defaultValue={dayjs(module1?.begin).isValid() ? dayjs(module1?.begin) : undefined}  onChange={(value) => change({ begin: dayjs(value).format() })} /> // value={dayjs(module.begin)} 
                </Col>
                <Col span={6}>
                    Завершение
                </Col>
                <Col span={18}>
                    <DatePicker name="end" defaultValue={dayjs(module1?.end).isValid() ? dayjs(module1?.end) : undefined} onChange={(value) => change({ end: dayjs(value).format() })} />
                </Col> */}
                <Col span={6}>
                </Col>
                <Col span={18}>
                    <Form
                        action={/* String(module1?.id) + */ 'destroy'}
                        method="post"
                        onSubmit={(event) => {
                            console.log(event);

                            if (
                                !window.confirm(`Подтвердите удаление`)
                            ) {
                                event.preventDefault();
                            }

                        }}

                    >
                        <button className='float-end btn btn-outline-danger'>
                            <BiTrash className="justify-content-between" />
                            Удалить модуль
                        </button>
                    </Form>
                    {/* <Button onClick={() => openThemes(module.id)} className='float-end'>
                        <BiPlus className="d-flex justify-content-between" />
                        Добавить тему
                    </Button> */}
                </Col>

            </Row>
        </Card>
    )
}


export default ModuleCard;