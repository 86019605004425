import { message } from "antd";
import { $authHost } from "./index";

export const fetchSilabus = async (classID, subjectID, begin, end) => {
    try {
        const { data } = await $authHost.get('api/silabus/silabus/' + classID + '/' + subjectID + '/' + begin + '/' + end)
        return data
    } catch {
        message.error("Ошибка загрузки силабуса!")
        return null
    }
}

export const fetchSilabusM = async (classID, subjectID, moduleID) => {
    try {
        const { data } = await $authHost.get('api/silabus/silabus/' + classID + '/' + subjectID + '/' + moduleID)
        return data
    } catch {
        message.error("Ошибка загрузки силабуса!")
        return null
    }
}

export const fetchSilabusData = async (classID, subjectID, begin, end) => {
    try {
        const { data } = await $authHost.get('api/silabus/data/' + classID + '/' + subjectID + '/' + begin + '/' + end)
        return data
    } catch {
        message.error("Ошибка загрузки данных силабуса!")
        return null
    }
}

export const fetchTree = async (classID, subjectID) => {
    const { data } = await $authHost.get('api/silabus/tree/' + classID + '/' + subjectID)
    return data
}

export const fetchModules = async (classID, subjectID) => {
    const { data } = await $authHost.get('api/silabus/modules/?classID=' + classID + '&subjectID=' + subjectID)
    return data
}

export const oneModule = async (moduleID) => {
    const { data } = await $authHost.get('api/silabus/module/' + moduleID)
    return data
}

export const createModule = async (classID, subjectID) => {
    const { data } = await $authHost.post('api/silabus/module', { classID, subjectID })
    return data
}

export const modyfyModule = async (moduleID, index, name, begin, end) => {
    const { data } = await $authHost.put('api/silabus/module', { moduleID, index, name, begin, end })
    return data
}

export const modyfyModuleO = async (object) => {
    try {
        const { data } = await $authHost.put('api/silabus/module', object)
        message.info("Сохранено!")
        return data
    } catch {
        message.error("Ошибка загрузки данных!")
        return null
    }
}

export const deleteModule = async (moduleID) => {
    const { data } = await $authHost.delete('api/silabus/module/' + moduleID)
    return data
}

export const fetchThemes = async (moduleID) => {
    const { data } = await $authHost.get('api/silabus/themes/' + moduleID)
    return data
}

export const fetchThemesByWorkType = async (workTypeID, classID, subjectID) => {
    const { data } = await $authHost.get('api/silabus/themesbyworktype/?classID=' + classID + '&subjectID=' + subjectID + '&workTypeID=' + workTypeID)
    return data
}

export const createTheme = async (moduleID) => {
    const { data } = await $authHost.post('api/silabus/theme', { moduleID })
    return data
}

export const modyfyTheme = async (themeID, index, name, workType, end) => {
    const { data } = await $authHost.put('api/silabus/theme', { themeID, index, name, workType, end })
    return data
}

export const modyfyThemeO = async (object) => {
    try {
        const { data } = await $authHost.put('api/silabus/theme', object)
        message.info("Сохранено!")
        return data
    } catch {
        message.error("Ошибка загрузки данных!")
        return null
    }

}

export const deleteTheme = async (themeID) => {
    const { data } = await $authHost.delete('api/silabus/theme/' + themeID)
    return data
}

export const oneTheme = async (themeID) => {
    const { data } = await $authHost.get('api/silabus/theme/' + themeID)
    return data
}

export const fetchRoutes = async (routeID) => {
    const { data } = await $authHost.get('api/silabus/routes/' + routeID)
    return data
}

export const createRoute = async (themeID) => {
    const { data } = await $authHost.post('api/silabus/route', { themeID })
    return data
}

export const modyfyRoute = async (routeID, index, name, color) => {
    const { data } = await $authHost.put('api/silabus/route', { routeID, index, name, color })
    return data
}

export const modyfyRouteO = async (object) => {
    try {
        const { data } = await $authHost.put('api/silabus/route', object)
        message.info("Сохранено!")
        return data
    } catch {
        message.error("Ошибка загрузки данных!")
        return null
    }

}

export const deleteRoute = async (routeID) => {
    const { data } = await $authHost.delete('api/silabus/route/' + routeID)
    return data
}

export const oneRoute = async (routeID) => {
    const { data } = await $authHost.get('api/silabus/route/' + routeID)
    return data
}

export const fetchDescriptions = async (descriptionID) => {
    const { data } = await $authHost.get('api/silabus/descriptions/' + descriptionID)
    return data
}

export const createDescription = async (routeID) => {
    const { data } = await $authHost.post('api/silabus/description', { routeID })
    return data
}

export const modyfyDescription = async (descriptionID, index, text) => {
    const { data } = await $authHost.put('api/silabus/description', { descriptionID, index, text })
    return data
}

export const modyfyDescriptionO = async (object) => {
    try {
        const { data } = await $authHost.put('api/silabus/description', object)
        message.info("Сохранено!")
        return data
    } catch {
        message.error("Ошибка загрузки данных!")
        return null
    }
}

export const deleteDescription = async (descriptionID) => {
    const { data } = await $authHost.delete('api/silabus/description/' + descriptionID)
    return data
}

export const oneDescription = async (descID) => {
    const { data } = await $authHost.get('api/silabus/description/' + descID)
    return data
}

export const fetchResponses = async (responseID, studentID) => {
    const { data } = await $authHost.get('api/silabus/responses/' + responseID + '/' + studentID)
    return data
}

export const fetchTeacherClassesSubjects = async () => {
    const { data } = await $authHost.get('api/teacher/classessubjects/')
    return data
}

export const fetchTeacherClasses = async () => {
    const { data } = await $authHost.get('api/teacher/classes/')
    return data
}

export const fetchTeacherSchedule = async () => {
    const { data } = await $authHost.get('api/teacher/schedule/')
    return data
}

export const fetchTeacherScheduleNew = async () => {
    const { data } = await $authHost.get('api/teacher/schedulenew/')
    return data
}

export const fetchTeacherSubjects = async () => {
    const { data } = await $authHost.get('api/teacher/subjects/')
    return data
}

export const fetchTeacherStudents = async (classID) => {
    const { data } = await $authHost.get('api/teacher/students/' + classID)
    return data
}

export const fetchStudentsScoresList = async (classID, subjectID, moduleID, themeID) => {
    const { data } = await $authHost.get('api/teacher/studentsscoreslist/' + classID + '/' + subjectID + '/' + moduleID + '/' + themeID)
    return data
}

export const fetchStudentsScoresListByThemeID = async (themeID) => {
    const { data } = await $authHost.get('api/teacher/studentsscoreslistbytheme/' + themeID)
    return data
}

export const modyfyStudentsScoresList = async (formData) => {
    const { data } = await $authHost.put('api/teacher/studentsscoreslist', formData)
    return data
}

export const getCurrentTheme = async (subjectID, studentID, classID) => {
    const { data } = await $authHost.get('api/teacher/currenttheme/' + subjectID + '/' + studentID + '/' + classID)
    return data
}

export const fetchTeacherScores = async (descriptionID, studentID) => {
    const { data } = await $authHost.get('api/silabus/responses/' + descriptionID + '/' + studentID)
    return data
}

export const modyfyTeacherScores = async (id, score, comment) => {
    const { data } = await $authHost.put('api/silabus/answer', { id, score, comment })
    return data
}

export const fetchAttendance = async (classID, attendanceID) => {
    console.log("FA", classID, attendanceID);
    if (attendanceID === 0 || attendanceID === undefined) {
        const { data } = await $authHost.get('api/teacher/attendance/?classID=' + classID)
        return data
    } else {
        const { data } = await $authHost.get('api/teacher/attendance/?attendanceID=' + attendanceID)
        return data
    }
    //return [{id: '56|34|34', studentName: 'Петров Вася', status: '', begin: '8:30', end: '9:30', comment: ''},
    //  {id: '56|34|35', studentName: 'Сидоров Петя', status: 'red', begin: '8:30', end: '9:30', comment: 'не пришел'}]
}

export const fetchAttendanceData = async (date) => {
    const { data } = await $authHost.get('api/teacher/attendancedata/' + date)
    return data
    // {shedulled: true, selectedClass: 1, classes: [{id: 1, name: Класс1, ]
}

export const fetchAttendanceStat = async (begin, end, classID, subjectID) => {
    const { data } = await $authHost.get('api/teacher/attendancestat/?classID=' + classID + '&subjectID=' + subjectID + '&begin=' + begin + '&end=' + end)
    return data
}

export const fetchScoreSheet = async (begin, end, classID, subjectID) => {
    const { data } = await $authHost.get('api/teacher/scoresheet/?classID=' + classID + '&subjectID=' + subjectID + '&begin=' + begin + '&end=' + end)
    return data
}

export const fetchStarSubData = async (studentID, tacts = '') => {
    try {
        const { data } = await $authHost.get('api/teacher/star/?studentID=' + studentID + '&tacts=' + tacts)
        return data
    } catch {
        message.error("Ошибка загрузки субьектной звезды")
        return null
    }
}

export const modyfyStarSubData = async (formData) => {
    const { data } = await $authHost.put('api/teacher/star/', formData)
    return data
}

export const modyfyAttendance = async (formData) => {
    const { data } = await $authHost.put('api/teacher/attendance/', formData)
    return data
}

export const getFileList = async (area, aid) => {
    const { data } = await $authHost.get(`/api/file/filelist/?area=${area}&aid=${Number(aid)}`)
    return data
}

export const fetchTacts = async () => {
    const { data } = await $authHost.get('api/teacher/tacts/')
    return data
}

export const fetchCurrentTact = async () => {
    const { data } = await $authHost.get('api/services/currenttact/')
    return data
}
