import React, { useEffect, useContext, useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container } from 'react-bootstrap';
import { fetchCurrentTact, fetchModules, fetchRoutes, fetchSilabus, fetchSilabusData, fetchTacts, fetchTeacherClasses, fetchTeacherClassesSubjects, fetchThemes } from '../../../http/teacherApi';
import { Context } from '../../..';
import MenuBar from '../../MenuBar';
import { Card, Col, Row, Select } from 'antd';
import { Outlet, useLoaderData } from 'react-router-dom';
import SilabusTable, { loadSilabus } from '../../utilites/SilabusTable/SilabusTable';
import HTMLViewer from '../../utilites/HTMLViewer';
import dayjs, { Dayjs } from 'dayjs'

import { FaPrint } from "react-icons/fa"
import { useReactToPrint } from "react-to-print";



const styleModules/* : React.CSSProperties */ = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleThemes/* : React.CSSProperties */ = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleRoutes/* : React.CSSProperties */ = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };
const styleDescriptions/* : React.CSSProperties */ = { background: '#efefef', padding: '3px', border: 'solid 1px gray'/* , writingMode: 'vertical-rl' */, minHeight: '400px', minWidth: '50px', fontSize: '0.rem', verticalAlign: 'top' };


export const journalLoader = async ({ params }) => {
    console.log("params", params);

    const { classID, subjectID, moduleID } = params;

    if (!classID || !subjectID) return null;

    var data = await fetchSilabusData(classID, subjectID, "2023-9-1", "2023-11-31");


    console.log(data);
    const { modules } = await loadSilabus({ classID, subjectID, begin: "2023-9-1", end: "2023-11-31", data, moduleID });
    console.log("loader silabus", modules);


    return { data, modules };
}

const TeacherClasses = observer(({ nextID }) => {
    const { teacher } = useContext(Context)

    //const { silabus, data } = useLoaderData();


    const [classes, setClasses] = useState([]);
    const [classSubjects, setClassSubjects] = useState([]);
    const [tacts, setTacts] = useState([]);
    const [modules, setModules] = useState(null);
    const [themes, setThemes] = useState(null);
    const [currentTact, setCurrentTact] = useState(null);
    const [routes, setRoutes] = useState(null);

    const [silabus, setSilabus] = useState(null);
    const [data, setData] = useState(null);


    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedTact, setSelectedTact] = useState(null);
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null);

    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const classes = await fetchTeacherClasses();
        setClasses(classes.map((item) => { return { ...item, link: item.id } }));
        teacher.setSelectedModule({})
        teacher.setSelectedTheme({})
        const data1 = await fetchTeacherClassesSubjects()
        setClassSubjects(data1)

        const tacts = await fetchTacts()
        setTacts(tacts.map((item) => { return { label: item.name, value: item.id } }))

        const currentTact = await fetchCurrentTact();
        console.log("currentTact", currentTact);
        setCurrentTact(currentTact);
        setSelectedTact(currentTact?.id | 0);
    }

    useEffect(() => {
        loadModules();
    }, [selectedClass, selectedSubject, selectedTact])

    const loadModules = async () => {
        if (selectedClass && selectedSubject && selectedTact) {
            const modules = await fetchModules(selectedClass, selectedSubject);
            setModules(modules.map((item, index) => { return { label: "Модуль " + item.index + ": " + item.name, value: item.id } }));
            console.log("modules", modules);
            setSelectedModule(null);
        }
    }

    useEffect(() => {
        loadThemes();
    }, [selectedModule])

    const loadThemes = async () => {
        if (selectedModule) {
            const themes = await fetchThemes(selectedModule);
            console.log("themess", themes, modules);
            setThemes(themes.map((item, index) => { return { label: item.name, value: item.id } }));
            setSelectedTheme(null)

            const data2 = await journalLoader({ params: { classID: selectedClass, subjectID: selectedSubject, moduleID: selectedModule} });
            setSilabus(data2['modules'])
            setData(data2['data'])
            //const silabus = data2['silabus']
            //const data  = data2['data']
            console.log("data2", data2);

        }



    }

    useEffect(() => {
        loadRoutes();
    }, [selectedTheme])

    const loadRoutes = async () => {
        if (selectedTheme) {
            const routes = await fetchRoutes(selectedTheme);
            console.log("routes", routes);
            setRoutes(routes.map((item, index) => { return { label: item.name, value: item.id } }));
            setSelectedRoute(null)
        }
    }


    return (
        <Card extra={<button onClick={handlePrint}><FaPrint /></button>}>
            <Row gutter={[/* {
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
            } */8, 8]}>
                <Col span={6}>
                    {/* <MenuBar menu={classes} /> */}
                    <Select
                        placeholder='Выберите класс'
                        options={classes.map((item) => { return { label: item.name, value: item.id } })}
                        style={{ width: '100%' }}
                        onChange={setSelectedClass}
                        key="class"
                    />
                </Col>
                <Col span={6}>
                    {selectedClass &&
                        <Select
                            placeholder='Выберите предмет'
                            options={classSubjects.filter((item) => item.classID === selectedClass).map((item) => { return { label: item.subjectName, value: item.subjectID } })}
                            style={{ width: '100%' }}
                            onChange={setSelectedSubject}
                            key={"subject" + selectedClass}
                        />
                    }
                </Col>
                <Col span={6}>
                    {selectedSubject &&
                        <Select
                            value={selectedTact}
                            placeholder='Выберите такт'
                            options={tacts}
                            style={{ width: '100%' }}
                            onChange={setSelectedTact}
                        //key={}
                        />
                    }
                </Col>
                <Col span={18}>
                    {modules &&
                        <Select
                            placeholder='Выберите модуль'
                            options={modules}
                            style={{ width: '100%' }}
                            onChange={setSelectedModule}
                            key={selectedClass + selectedSubject + selectedTact}
                        />
                    }
                </Col>
{/*                 <Col span={18}>
                    {(themes && selectedModule) &&
                        <Select
                            placeholder='Выберите тему'
                            options={themes}
                            style={{ width: '100%' }}
                            onChange={setSelectedTheme}
                            key={selectedClass + selectedSubject + selectedTact + selectedModule}
                        />
                    }
                </Col>
                <Col span={18}>
                    {(routes && selectedTheme) &&
                        <Select
                            placeholder='Выберите умение'
                            options={routes}
                            style={{ width: '100%' }}
                            onChange={setSelectedRoute}
                            key={selectedClass + selectedSubject + selectedTact + selectedModule + selectedTheme}
                        />
                    }
                </Col> */}
            </Row>
            {/* <Row gutter={[8, 8]}>
                <Col span={24}>
                    {modules &&

                        (
                            <Test
                                options={modules.filter((module) => module.value === selectedModule)[0]}
                            />

                        )
                    }
                </Col>

            </Row> */}
            <div ref={componentRef}  key={silabus}>
                {(silabus && data) &&
                    < SilabusTable silabus={silabus} data={data} />
                }
            </div>
        </Card>
    );
});

const Test = ({ options }) => {
    console.log("test", options);
    return (
        <>
            {options?.label}
        </>
    )

}

export default TeacherClasses;