import React, { useContext, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { Form, Button, Dropdown } from "react-bootstrap";
//import { Form, Button, Col, Row, Dropdown, Container } from "react-bootstrap";
import { Context } from '../../..';
import { observer } from "mobx-react-lite";
import { modifyUser, createUser } from '../../../http/adminApi';
import ClassSubjectDropdown from '../../utilites/ClassSubjectDropdown';
//import { Checkbox, Col, Row } from 'antd';
import { Checkbox } from 'antd';

import { Col, Row } from "antd";


const AdminUser = observer(({ show, onHide, editUser, userRole, users, setUsers }) => {
  const { admin } = useContext(Context)
  //console.log("парентс", admin.parents);
  //const { user } = useContext(Context)

  const [login, setLogin] = useState('')
  const [name, setName] = useState('')
  const [birthday, setBirthday] = useState('')
  const [foto, setFoto] = useState(null)
  const [password, setPassword] = useState('')
  const [roles, setRoles] = useState([])
  const [properties, setProperties] = useState([])

  function setInputs() {
    //console.log("fdsfsdf", editUser);
    setLogin(editUser?.login)
    setName(editUser?.name)
    if (Array.isArray(editUser?.roles)) {
      editUser?.roles?.length > 0 ? setRoles(editUser?.roles) : setRoles([editUser?.role])
    } else {
      editUser?.roles?.length > 0 ? setRoles(JSON.parse(editUser?.roles)) : setRoles([editUser?.role])
    }
    //setRoles(JSON.parse(editUser?.roles))
    setPassword('')
    editUser?.birthday ? setBirthday(editUser.birthday.split('T')[0]) : setBirthday('')
    editUser?.foto ? setFoto(editUser.foto) : setFoto(null)
    //      if (editUser?.birthday) setBirthday(editUser.birthday) else setBirthday('')
    //      if (editUser?.foto) setFoto(editUser.foto) else setFoto(null) 
    //setPassword(editUser?.password)
    //      if (editUser?.properties) setProperties(editUser.properties)
    editUser?.properties ? setProperties(editUser.properties) : setProperties([])
  }

 /*  const selectFile = e => {
    setFoto(e.target.files[0])
  }
 */
  const addUserToLists = userID => {
    setUsers([{ id: userID, login: login, name: name, password: password, role: userRole, birthday: birthday, foto: foto, properties: properties }, ...users])
    if (userRole === 'parent') {
      admin.setParents([{ id: userID, login: login, name: name, password: password, role: userRole, birthday: birthday, foto: foto, properties: properties }, ...admin.parents])
      //userList('parent').then(data => admin.setParents(data))
    }
  }

  function newUser() {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('login', login)
    formData.append('password', password)
    formData.append('role', userRole)
    formData.append('roles', JSON.stringify(roles))
    formData.append('birthday', birthday)
    formData.append('foto', foto)
    formData.append('properties', JSON.stringify(properties))

    /* const data =  */createUser(formData).then(id =>
      addUserToLists(id))
    //  setUsers([{ id: id, login: login, name: name, password: password, role: userRole, birthday: birthday, foto: foto, properties: properties }, ...users]))
    // admin.setSelectedUser(editUser)
    // userList(userRole).then(data => admin.setUsers(data))
  }

  function changeUser() {
    editUser.login = login
    editUser.name = name
    editUser.password = password
    editUser.role = userRole
    editUser.roles = roles
    editUser.properties = properties

    admin.setSelectedUser(editUser)

    const formData = new FormData()
    formData.append('id', editUser.id)
    formData.append('name', editUser.name)
    formData.append('login', editUser.login)
    formData.append('password', editUser.password)
    formData.append('role', userRole)
    formData.append('roles', JSON.stringify(editUser.roles))
    formData.append('birthday', birthday)
    formData.append('foto', foto)
    formData.append('properties', JSON.stringify(properties))
    modifyUser(formData)
    //  console.log("PR", properties);
    //setUsers(users.map(i => i.id === editUser.id ? { ...i, id: editUser.id, login: login, name: name, password: password, role: userRole, birthday: birthday, foto: foto, properties: properties } : i))
    admin.setUsers(admin.users.map(i => i.id === editUser.id ? { ...i, id: editUser.id, login: login, name: name, password: password, role: userRole, birthday: birthday, foto: foto, properties: properties } : i))
    // TODO изменения списков, изменения списка ролей если меняеш себя
    //console.log("PR11", user.user.id, editUser.id, user.user.id === editUser.id, roles, user.roles);
    /* if (user.user.id === editUser.id) {
      user.setRoles(roles)
    } */

    if (roles.find(i => i === 'student')) {

    } else {
      //admin.
    }
  }

  function clickUser() {
    if (!editUser?.id) {
      newUser()
    } else {
      changeUser()
    }
    onHide()
  }

  const onChangeRoles = (checkedValues) => {
    console.log('checked = ', checkedValues);
    setRoles(checkedValues)
  };

  const addProperty = () => {
    //setProperties(properties['Свойство'] = '');
    //  setProperties([...properties, {key: '', value: '', id: Date.now()}])
    setProperties([...properties, { key: '', value: '', id: Date.now() }])
  }
  const removeProperty = (id) => {
    //setProperties(delete properties[key])
    setProperties(properties.filter(i => i.id !== id))
  }
  /*     const removeProperty = (id) => {
        setProperties(properties.filter(i => i.id !== id))
      } */
  const changeProperty = (key, value, id) => {
    //setProperties(properties[key] = value);
    //  console.log("CP:",key," ",value," ",id);
    //  console.log("bef",properties);
    //      setProperties(properties.map(i => i.id === id ? {...i, [key]: value} : i))
    setProperties(properties.map(i => i.id === id ? { ...i, key: key, value: value } : i))
    //  console.log("aft",properties);
  }

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Наберите для фильтрации..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
            )}
          </ul>
        </div>
      );
    },
  );

  return (
    <Modal
      onShow={setInputs}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Редактирование пользователя
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Измените данные или введите данные нового пользователя.
        </p>
        <Form>
          <Form.Control className="mt-2 mb-2"
            placeholder='Введите логин'
            value={login}
            onChange={e => setLogin(e.target.value)}
            type='login'
          />
          <Form.Control className="mt-2 mb-2"
            placeholder='Введите имя'
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <Form.Control className="mt-2 mb-2"
            placeholder='Введите дату рождения'
            value={birthday}
            onChange={e => setBirthday(e.target.value)}
            type='date'
          />
          {/*             {foto ? <Image fluid width={300} height={300} src={process.env.REACT_APP_API_URL + '/' + foto}/> : ""}
            <Form.Control className="mt-2 mb-2"
              placeholder='Выберите фоторгафию'
              //value={foto}
              onChange={selectFile}
              type='file'
            /> */}
          <Form.Control className="mt-2 mb-2"
            placeholder='Введите пароль'
            value={password}
            onChange={e => setPassword(e.target.value)}
          //type='password'
          />
          {/*           <Button
            variant={"outline-dark"}
            onClick={addProperty}
          >
            Добавить новое свойство
          </Button> */}
          {/* {console.log('ropes = ', roles)} */}
          <Checkbox.Group
            style={{
              width: '100%',
              marginBottom: '15px',
            }}
            value={roles}
            onChange={onChangeRoles} >
            <Row>
              <Col span={4}>
                <Checkbox disabled={userRole === "student"} value="student">Студент</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox disabled={userRole === "parent"} value="parent">Родитель</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox disabled={userRole === "teacher"} value="teacher">Учитель</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox disabled={userRole === "tutor"} value="tutor">Тьютор</Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox disabled={userRole === "ADMIN"} value="ADMIN">Администратор</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>

          {properties?.map(i =>
            <Row /* className="mt-4" */ key={i.id} gutter={[8,8]}>
              <Col span={5}>
                <Dropdown>
                  <Dropdown.Toggle disabled={i.value}>{i.key || "Выберите свойство"}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    {admin.propertiesTypes.map(ptype =>
                      <Dropdown.Item
                        onClick={() => changeProperty(ptype.key, '', i.id)}
                        key={ptype.id}
                      >
                        {ptype.key}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {/*                                 <Form.Control
                                    value={i.key}
                                    onChange={(e) => changeProperty('key', e.target.value, i.id)}
                                    placeholder="Выберет свойства"
                                /> */}
              </Col>
              <Col span={9}>
                {(admin.propertiesTypes.filter(j => j.key === i.key)[0]?.type === "parentid") &&
                  /*                   <Select
                                      showSearch
                                      placeholder={admin.parents.filter(j => j.id == i.value)[0]?.name || "Выберите родителя"}
                                      optionFilterProp="children"
                                      onChange={(value) => changeProperty(i.key, value, i.id)} 
                            
                                      filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                      options={admin.parents.map((parent) => ({
                                        label: parent.name,
                                        value: parent.id,
                                      }))}
                                    /> */
                  <Dropdown /* search */>
                    <Dropdown.Toggle>{admin.parents.filter(j => j.id == i.value)[0]?.name || "Выберите родителя"}</Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                      {admin.parents.map(parent =>
                        <Dropdown.Item
                          onClick={() => changeProperty(i.key, parent.id, i.id)}
                          key={parent.id}
                        >
                          {parent.name}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                }
                {(admin.propertiesTypes.filter(j => j.key === i.key)[0]?.type === "classid") &&
                  <Dropdown>
                    <Dropdown.Toggle>{admin.classes.filter(j => j.id == i.value)[0]?.name || "Выберите класс"}</Dropdown.Toggle>
                    <Dropdown.Menu>
                      {admin.classes.map(clas =>
                        <Dropdown.Item
                          onClick={() => changeProperty(i.key, clas.id, i.id)}
                          key={clas.id}
                        >
                          {clas.name}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                }
                {(admin.propertiesTypes.filter(j => j.key === i.key)[0]?.type === "clasid_subjectid") &&
                  <ClassSubjectDropdown
                    /* classes={admin.classes}
                    subjects={admin.subjects} */
                    classes={admin.classes.filter(p => properties.filter(b => b.key == "Класс").find(b => b.value == p.id))}
                    subjects={admin.subjects.filter(p => properties.filter(b => b.key == "Предмет").find(b => b.value == p.id))}
                    property={i}
                    changeProperty={changeProperty}
                  />
                }
                {(admin.propertiesTypes.filter(j => j.key === i.key)[0]?.type === "subjectid") &&
                  <Dropdown>
                    <Dropdown.Toggle>{admin.subjects.filter(j => j.id == i.value)[0]?.name || "Выберите предмет"}</Dropdown.Toggle>
                    <Dropdown.Menu>
                      {admin.subjects.map(subject =>
                        <Dropdown.Item
                          onClick={() => changeProperty(i.key, subject.id, i.id)}
                          key={subject.id}
                        >
                          {subject.name}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                }
                {(admin.propertiesTypes.filter(j => j.key === i.key)[0]?.type === "string") &&
                  <Form.Control
                    value={i.value}
                    onChange={(e) => changeProperty(i.key, e.target.value, i.id)}
                    placeholder="Введите описание свойства"
                  />
                }
                {/*                                 {(admin.propertiesTypes.filter(j => j.key === i.key)[0]?.type === "parentid") ?
                                <Dropdown>
                                  <Dropdown.Toggle>{admin.parents.filter(j => j.id === i.value)[0]?.name || "Выберите родителя"}</Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {admin.parents.map(parent =>
                                        <Dropdown.Item
                                            onClick={() => changeProperty(i.key, parent.id, i.id)}
                                            key={parent.id}
                                        >
                                            {parent.name}
                                        </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>                                 
                                :
                                <Form.Control
                                  value={i.value}
                                  onChange={(e) => changeProperty(i.key, e.target.value, i.id)}
                                  placeholder="Введите описание свойства"
                                />                                
                                } */}
              </Col>
              <Col span={2}>
                <Button
                  onClick={() => removeProperty(i.id)}
                  variant={"outline-danger"}
                >
                  Удалить
                </Button>
              </Col>
            </Row>
          )}
          <Button
            variant={"outline-dark"}
            onClick={addProperty}
            style={{ marginTop: '15px' }}
          >
            Добавить новое свойство
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        <Button variant="outline-success" onClick={clickUser}>Сохранить</Button>
      </Modal.Footer>
    </Modal>
  );
});

export default AdminUser;