import { Button, Card, Col, DatePicker, Row, Tree } from "antd";
import { GetProps, Typography } from 'antd';
import { ReactNode, useEffect, useState } from "react";
import { BiAddToQueue, BiCommentAdd, BiDotsHorizontal, BiPlus, BiSolidAddToQueue, BiTrash } from "react-icons/bi";
import { createTheme, deleteModule, deleteTheme, fetchModules, fetchRoutes, modyfyModuleO, modyfyRouteO, modyfyThemeO, oneModule, oneTheme } from "../../http/teacherApi";
import dayjs from 'dayjs'
import MyDatePicker from "../MyDatePicker";
import Select from 'react-select'
import { fetchWorkTypes } from "../../http/adminApi";
import { Form, Navigate, Outlet, redirect, useLoaderData, useNavigate } from "react-router-dom";
import DescriptionCard from "../DescriptionCardList";
import DescriptionCard2 from "../DescriptionCard2";

const { DirectoryTree } = Tree;

const { Paragraph } = Typography;
dayjs.locale('ru')

export async function loaderThemeCard2({ params }: any) {
    console.log("theme card2 loader", params);

    const rawWorkTypes = await fetchWorkTypes();
    const workTypes = rawWorkTypes.map((item: any) => { return { value: item.id, label: item.name, color: '#00B8D9' } });

    const { themeID } = params;

    const target = { id: themeID };

    if (themeID) {
        const theme = await oneTheme(themeID);

        const routes = await fetchRoutes(themeID);

        return { theme, workTypes, routes, target }
    }




    return null
}

export async function createThemeCard2({ params }: any) {
    const { moduleID } = params;
    const ret = await createTheme(moduleID);
    console.log("new themeID:", ret);
    return redirect('../' + ret);
}

export async function destroyThemeCard2({ params, request }: any) {
    console.log("destroyThemeCard", params, request);
    const { themeID, moduleID } = params
    if (themeID) {
        await deleteTheme(themeID);

    }
    return redirect('../../' + moduleID);
}


export async function actionThemeCard2({ params, request }: any) {
    console.log("actionThemeCard", params, request);
    const { themeID } = params
    if (themeID) {
        //await deleteTheme(themeID);
    }
    return redirect('../..');
}



interface Module {
    id: number,
    index?: string,
    name?: string,
    begin?: string | null,
    end?: string,
    workType?: number
}

interface DataNode {
    title: string | ReactNode;
    key: string | number;
    isLeaf?: boolean;
    children?: DataNode[];
    selectable?: boolean;
}

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;

/* const treeData: DataNode[] = [
    { title: 'Умение 1:', key: '10', },
    { title: 'Умение 2', key: '11' },
    { title: 'Умение 3', key: '20', isLeaf: true },
]; */


const ThemeCard2 = (params: { id: number | any }) => {

    const { workTypes, theme, routes, target } = useLoaderData() as any;
    const navigate = useNavigate();


    const [workTypes1, setWorkTypes] = useState([])
    const [module, setModule] = useState<Module>({ id: 0 });

    const _editable = (props: any) => {
        return (
            <Paragraph
                editable={{
                    tooltip: 'Редактировать',
                    onChange: (value) => change({ props, value }),
                    triggerType: ['icon'/* , 'text' */],
                }}
            >
                {props?.name}
            </Paragraph>
        )
    }




    const treeData = routes.map((item: any) => { return { title: _editable(item), key: item?.id } })


    /*  useEffect(() => {
         load(id);
     }, []);
 
     const load = async (id: any) => {
         const ret = await oneTheme(id);
         setModule(ret);
         const rawWorkTypes = await fetchWorkTypes();
         setWorkTypes(rawWorkTypes.map((item: any) => { return { value: item.id, label: item.name, color: '#00B8D9' } }));
     }
  */

    useEffect(() => {
        //loadModule(id);
        setModule(theme);
        setWorkTypes(workTypes);
    }, [theme, workTypes]);

    const change = async (value: any) => {
        console.log("ch idx", value);

        setModule({ ...module, ...value });
        //modyfyRouteO({ themeID: module.id, ...value })

    }

    const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
        console.log('Trigger Select', keys, info);
        //    setSelectedKeys(keys);
        //    setExpandedKeys(keys);
        //        navigate(`${target?.id}/${String(keys[0]).replace(/-/gi, '/')}`);
        navigate(`${String(keys[0]).replace(/-/gi, '/')}`);
        //    const { _type, id }: number | any = key2number(keys[0]);

        //        switch (_type) {
        //      case 'module': navigate(target.id + '/module/' + id);  break;//setSelected(<Navigate to={'module/'+id}/>); break; //{/* <ModuleCard key={id} id={id}></ModuleCard> */}
        //      case 'theme': navigate(target.id + '/theme/' + id); break;//setSelected(<ThemeCard key={id} id={id}></ThemeCard>); break;
        //          case 'route': setSelectedView(<RouteCard key={id} id={id}></RouteCard>); break;
        //          case 'description': setSelectedView(<DescriptionCard key={id} id={id}></DescriptionCard>); break;
        //          default: setSelectedView(EmptySelected());
        //        }
    };
    return (
        <>
            {/* <Card title={'Тема: ' + module.name} key={'theme' + module.id}> */}
            <Row gutter={[8, 8]}>
                <Col span={24}><h5>{'Тема: ' + module.name}</h5></Col>
                <Col span={24}>
                    <Card title='Умения'>
                        <DirectoryTree treeData={treeData} onSelect={onSelect} /* selectedKeys={selectedKeys} expandedKeys={expandedKeys}   onExpand={onExpand} */ showIcon={false} expandAction={false} />
                    </Card>
                </Col>
                <Col span={24}>
                    <Outlet />
                    {/* <DescriptionCard2 />
                    <DescriptionCard2 />
                    <DescriptionCard2 />
                    <DescriptionCard2 />
                    <DescriptionCard2 /> */}
                </Col>
            </Row>

            {/*             <Row gutter={[4, 4]}>
                <Col span={6}>
                    Порядковый номер
                </Col>
                <Col span={18}>
                    <Paragraph
                        editable={{
                            tooltip: 'Порядковый номер',
                            onChange: (value) => change({ index: value }),
                            triggerType: ['icon', 'text'],
                        }}
                    >
                        {String(module.index)}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    Разделы
                </Col>
                <Col span={18}>
                    <Paragraph
                        editable={{
                            tooltip: 'Введите название',
                            onChange: (value) => change({ name: value }),
                            triggerType: ['icon', 'text'],
                        }}
                    >
                        {module.name}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    Тип контроля
                </Col>
                <Col span={18}>
                    <Select
                        onChange={(value: any) => change({ workType: value?.value })}
                        name="workType"
                        placeholder='Выберите тип контроля'
                        value={workTypes.filter((item: any) => item.value === module.workType)}
                        options={workTypes}
                    />
                </Col>

                <Col span={6}>
                    Начало
                </Col>
                 <Col span={18}>
                    <DatePicker name="begin" defaultValue={dayjs(module?.begin).isValid() ? dayjs(module?.begin) : undefined}  onChange={(value) => change({ begin: dayjs(value).format() })} /> 
                </Col>
                <Col span={6}>
                    Завершение
                </Col>
                <Col span={18}>
                    <DatePicker name="end" defaultValue={dayjs(module?.end).isValid() ? dayjs(module?.end) : undefined} onChange={(value) => change({ end: dayjs(value).format() })} />
                </Col>

                <Col span={6}>
                </Col>
                <Col span={18}>
                <Form
                        action={ 'destroy'}
                        method="post"
                        onSubmit={(event) => {
                            console.log(event);

                            if (
                                !window.confirm(`Подтвердите удаление`)
                            ) {
                                event.preventDefault();
                            }

                        }}

                    >
                        <button className='float-end btn btn-outline-danger'>
                            <BiTrash className="justify-content-between" />
                            Удалить тему
                        </button>
                    </Form>

                </Col>

            </Row> */}
            {/* </Card> */}
        </>
    )
}


export default ThemeCard2;