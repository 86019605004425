import React, { FC, useContext, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Context } from "..";

const RequireAuth = ({ children }) => {

    const location = useLocation();

    const { store } = useContext(Context);/*  as any; */



    const auth = /* useMemo( () => {
        return*/ store?.isAuth | false;
    /*} , [store]) */ 
//    const loading = user?.isLoading | false;
    //console.debug("RequireAuth", auth, location);

    if (!auth) {
        return <Navigate to='/login' state={{ from: location }} />
    }

    return children;

}

export default RequireAuth;