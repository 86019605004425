import React, { useEffect, useContext, useState } from 'react';
//import Table from 'react-bootstrap/Table';
import { Table, Tooltip } from 'antd';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import { Context } from '../..';
import { Col, InputNumber, message, Row } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import { DatePicker } from 'antd';
import AttendanceStudentStatus from '../teacher/statistic/AttendanceStudentStatus';
import AttendanceStudentHist from '../teacher/statistic/AttendanceStudentHist';
import { fetchAttendanceStat } from '../../http/teacherApi';
import { getSubjectsByClassID } from '../../http/studentApi';

const { RangePicker } = DatePicker;

const TutorAttendanceStat = observer(() => {
    const { tutor } = useContext(Context)

    const dateFormat = 'DD.MM.YYYY';
    const dateFormatForDB = 'YYYY-MM-DD';

    //const [fcTitle, setFCTitle] = useState(`${tutor.selectedClass?.name} / ${tutor.selectedSubject?.name}`)
 
    const firstColumn = {
        title: 'Студенты',
        dataIndex: 'name',
        fixed: 'left',
        width: 200,
    }
    const lastColumn = {
        title: 'Сводная гистограмма',
        dataIndex: 'hist',
        fixed: 'right',
        width: 300,
        align: 'center',
    }

    const [studentsList, setStudentsList] = useState([])
    const [columns, setColumns] = useState([firstColumn, lastColumn])
    const [subjects, setSubjects] = useState([])

    //    const [begin, setBegin] = useState(moment(moment.now() - 604800000))
    //    const [end, setEnd] = useState(moment(moment.now()))

    useEffect(() => {
        getSubjectsByClassID(tutor.selectedClass?.id).then(data => setSubjects(data))
        loadData(tutor.statBegin, tutor.statEnd, tutor.selectedClass?.id, tutor.selectedSubject?.id)
    }, [])

    const loadData = async (begin, end, classID, subjectID) => {
        const data = await fetchAttendanceStat(begin.format(dateFormatForDB), end.format(dateFormatForDB), classID, subjectID)

        let colData = [firstColumn]
        data.columns.map((col) => {
            colData.push({
                title: <Tooltip title={col.begin + '-' + col.end} color="grey"><div style={{ writingMode: 'sideways-lr' }}>{col.date}</div></Tooltip>,
                dataIndex: col.id,
                width: 30,
            })
        })
        colData.push(lastColumn)
        setColumns(colData)

        setStudentsList(data.students.map(stu => {
            for (var key in stu) {
                if (key != 'id' && key != 'name' && key != 'hist' && key != 'key') {
                    stu[key] = <AttendanceStudentStatus cell={stu[key]} />
                }
            }
            return {
                ...stu,
                ['key']: stu.id,
                ['hist']: <AttendanceStudentHist hist={stu.hist} />
            }
        }))

    }

    const changePeriod = (period) => {
        tutor.setStatBegin(period[0])
        tutor.setStatEnd(period[1])
        //setBegin(period[0])
        //setEnd(period[1])
        loadData(period[0], period[1], tutor.selectedClass?.id, tutor.selectedSubject?.id)
    }

    const setClassID = (classID) => {
        tutor.setSelectedClass(tutor.classes.find(i => i.id == classID))
        //setFCTitle(`${tutor.classes.find(i => i.id == classID)?.name} / ${tutor.selectedSubject?.name}`)
        var selectedSubjectID = tutor.selectedSubject?.id
        getSubjectsByClassID(classID).then(data => {
            setSubjects(data)
            console.log("DF", data.find(i => i.id == selectedSubjectID));
            if (!data.find(i => i.id == selectedSubjectID)) {
                selectedSubjectID = data[0]?.id
                tutor.setSelectedSubject(data[0])
            } 
            loadData(tutor.statBegin, tutor.statEnd, classID, selectedSubjectID)
        })
       // loadData(tutor.statBegin, tutor.statEnd, classID, selectedSubjectID)
    }

    const setSubjectID = (subjectID) => {
        tutor.setSelectedSubject(tutor.subjects.find(i => i.id == subjectID))
        //setFCTitle(`${tutor.selectedClass?.name} / ${tutor.subjects.find(i => i.id == subjectID)?.name}`)
        loadData(tutor.statBegin, tutor.statEnd, tutor.selectedClass?.id, subjectID)
    }

    return (
        <Container>
            <RangePicker
                //value={[begin, end]}
                value={[tutor.statBegin, tutor.statEnd]}
                format={[dateFormat, dateFormat]}
                allowClear={false}
                onChange={(value) => changePeriod(value)}
            /*  disabledDate={disabledDate}
             onCalendarChange={(val) => setDates(val)}
             onChange={(val) => setValue(val)}
             onOpenChange={onOpenChange} */
            />

            <Form className="mx-2" style={{ marginTop: '10px' }}> 
                <Form.Select onChange={(e) => setClassID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={tutor.selectedClass?.id} aria-label="Default select example">
                    {tutor.classes.map(clas =>
                        <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => setSubjectID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={tutor.selectedSubject?.id} aria-label="Default select example">
                    {subjects.map(subject =>
                        <option key={'subject' + subject.id} value={subject.id}>{subject.name}</option>
                    )}
                </Form.Select>
            </Form>

            <Table
                style={{ marginTop: '15px' }}
                columns={columns}
                dataSource={studentsList}
                pagination={false}
                /*   pagination={{
                      pageSize: 50,
                  }} */
                scroll={{
                    x: 500,
                }}
            />
        </Container>
    );
});

export default TutorAttendanceStat;